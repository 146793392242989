import React from 'react';
import BaseComponent from './BaseComponent';
import Autosuggest from 'react-autosuggest';
import * as DiagnosisActions from '../../actions/diagnosis-actions'
import * as DrugActions from '../../actions/drug-actions'
import * as ClientActions from '../../actions/client-actions'
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { withRouter, Route } from 'react-router-dom';

class HomeSearchComponent extends BaseComponent {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: []
    };
  }

  componentDidMount(){
    if(this.props.bugsDrugsDiagnoses.length == 0){
      this.props.clientActions.getBugsDrugsDiagnoses();
    }
  }

  escapeRegexCharacters = (str) => {
    gtag('event', 'input', {
     'event_category': 'Typed Search',
     'event_label': str
    });
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  // getSuggestions(value) {
  //   const escapedValue = escapeRegexCharacters(value.trim().toLowerCase());
  //   if (escapedValue === '') {
  //     return [];
  //   }
  //   //const regex = new RegExp('^' + escapedValue, 'i');
  //   const regex = new RegExp(escapedValue);
  //   const plantsName = this.state.plantsName;
  //   return plantsName.filter(plant => regex.test(plant.plant_name.toLowerCase()));
  // }

  getSuggestions = (value) => {
    if(this.props.bugsDrugsDiagnoses){
      const escapedValue = this.escapeRegexCharacters(value.trim().toLowerCase());

      if (escapedValue === '') {
        return [];
      }

      // const regex = new RegExp('^' + escapedValue, 'i');

      const regex = new RegExp(escapedValue);

      return this.props.bugsDrugsDiagnoses
        .map(section => {
          return {
            type: section.type,
            objects: section.objects.filter(object => regex.test(object.name.toLowerCase()))
          };
        })
        // .filter(section => section.objects.length > 0);
    }
  }

  getSuggestionValue = (suggestion) => {
    return suggestion.name;
  }

  renderSuggestion = (suggestion) => {
    return (
      <span>{suggestion.name}</span>
    );
  }

  renderSectionTitle = (section) => {
    return (
      <strong>{section.type}</strong>
    );
  }

  getSectionSuggestions = (section) => {
    return section.objects;
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.setState({value: ''})
    var type = this.props.bugsDrugsDiagnoses[sectionIndex].type
    var objectId = suggestion._id
    if(type === "bacteria"){
      this.props.history.push('/susceptibilityMatrix/bug/'+objectId)
    }
    if(type === "drug"){
      this.props.history.push('/dosingGuideline/'+objectId)
    }
    if(type === "diagnosis"){
      this.props.history.push('/diagnosis/'+objectId)
      // this.props.diagnosisActions.getCurrentDiagnosisCard(objectId)
    }
    gtag('event', 'click', {
     'event_category': 'Search Item Selected',
     'event_label': type + ": " + suggestion.name
    });

  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search bug, drug or diagnosis...",
      value,
      onChange: this.onChange
    };

    return(<div></div>);
    // return (
    //   <Autosuggest
    //     multiSection={true}
    //     suggestions={suggestions}
    //     onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
    //     onSuggestionsClearRequested={this.onSuggestionsClearRequested}
    //     onSuggestionSelected={this.onSuggestionSelected}
    //     getSuggestionValue={this.getSuggestionValue}
    //     renderSuggestion={this.renderSuggestion}
    //     renderSectionTitle={this.renderSectionTitle}
    //     getSectionSuggestions={this.getSectionSuggestions}
    //     inputProps={inputProps} />
    // );
  }
}

function mapStateToProps(state){
  return{
    bugsDrugsDiagnoses: state.clientState.bugsDrugsDiagnoses
  }
}

function mapDispatchToProps(dispatch){
  return{
    diagnosisActions: bindActionCreators(DiagnosisActions, dispatch),
    clientActions: bindActionCreators(ClientActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeSearchComponent));
