export default {
  bacteriaList: [],
  bacteria:{},
  drugList: [],
  drug:{},
  drugDosage:{},
  drugDosing:{},
  filteredDrugDosing: [],
  drugDosings:[],
  configReference:[],
  diagnoseList:[],
  diagnoseByGroupNameList: [],
  diagnose:{},
  diagnosisCard:{},
  lastPublishedDiagnosisCard:{},
  lastPublishedDrugDosing:{},
  SMList:[],
  SM:{},
  currentSM:{},
  SMDrugs:[],
  SMBugs:[],
  smLineItem:{},
  userList: [],
  user:{},
  loggedInUser:{},
  CurrentSM:{},
  drugsWithDosingList:[],
  bugsDrugsDiagnoses:[],
  alertsList:[]
}
