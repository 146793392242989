import React from 'react';
import BaseComponent from '../common/BaseComponent';

class DocumentsComponent extends BaseComponent {
  constructor(props) {
     super(props);
     this.state = {
     };
   }

   componentDidMount(){
   }

   componentWillMount(){
   }

   trackEvent(resource){
     gtag('event', 'click', {
       'event_category': 'Resource Materials',
       'event_label': resource
     })
   }

  render(){
    return(
        <div className="page-content" id="documents-view">
          <div className="row">
            <div className="col-12 bottom-margin">
              <ul className="list-group">
                <li className="list-group-item list-group-title">Additional Resources available for download</li>
                <li className="list-group-item" onClick={()=>{this.trackEvent("Duration of Therapy")}}>
                  <a href="assets/documents/Duration_of_Therapy_Long_for_Web.pdf" target="_blank">Duration of Therapy</a>
                  <i className="ml-2 fa fa-file-text-o" aria-hidden="true"></i>
                </li>
                <li className="list-group-item" onClick={()=>{this.trackEvent("Green Card 2018")}}>
                  <a href="assets/documents/BCH_Antibiogram_Brochure_2018.pdf" target="_blank">Green Card 2018</a>
                  <i className="ml-2 fa fa-file-text-o" aria-hidden="true"></i>
                </li>
                <li className="list-group-item" onClick={()=>{this.trackEvent("NICU Dosing Card")}}>
                  <a href="assets/documents/NICU_Dosing_Card_FINALv4_061017.pdf" target="_blank">NICU Dosing Card</a>
                  <i className="ml-2 fa fa-file-text-o" aria-hidden="true"></i>
                </li>
                <li className="list-group-item" onClick={()=>{this.trackEvent("Renal Dosing Card")}}>
                  <a href="assets/documents/renal-dosing-card.pdf" target="_blank">Renal Dosing Card</a>
                  <i className="ml-2 fa fa-file-text-o" aria-hidden="true"></i>
                </li>
                {/* <li className="list-group-item">
                  <a href="#">Neonatal Pink Card</a>
                  <i className="ml-2 fa fa-file-text-o" aria-hidden="true"></i>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
    )
  }
}


export default DocumentsComponent;
