import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import BaseComponent from '../common/BaseComponent';
import { Fa } from 'mdbreact';

class SMLegendComponent extends BaseComponent {

    constructor(props) {
        super(props)
        this.state = {
          legendOpen : false
        }
    }

    componentDidMount(){
    }

    toggleLegend = () => {
      if(this.state.legendOpen){
        this.setState({legendOpen:false})
      }else{
        this.setState({legendOpen:true})
        gtag('event', 'click', {
           'event_category': 'SM',
           'event_label': "Legend opened"
         })
      }
    }


    render() {

      return(
        <div onClick={this.toggleLegend} className={"sm-legend d-inline-flex " + (!this.state.legendOpen && "closed")}>
          <div className="box-content row">

            <div className="sm-legend-item col-12 d-flex align-items-center">
                <div className="legend-square range1 text-center text-white">S</div>
                <div className="legend-description">90-100% susceptible</div>
            </div>
            {/* <div className="sm-legend-item col-12 d-flex align-items-center">
              <div className="legend-square range1"></div>
              <div className="legend-description">90-100% susceptible</div>
            </div> */}
            <div className="sm-legend-item col-12 d-flex align-items-center">
              <div className="legend-square range2"></div>
              <div className="legend-description">80-89% susceptible</div>
            </div>
            <div className="sm-legend-item col-12 d-flex align-items-center">
              <div className="legend-square range3 text-center"><div>S/R</div></div>
              <div className="legend-description">70-79% susceptible</div>
            </div>
            <div className="sm-legend-item col-12 d-flex align-items-center">
                <div className="legend-square range4 text-center"></div>
                <div className="legend-description">&#60;70% susceptible</div>
            </div>
            <div className="sm-legend-item col-12 d-flex align-items-center">
              <div className="legend-square range-nottested text-center">-</div>
              <div className="legend-description">Drug not tested</div>
            </div>
            <div className="sm-legend-item col-12 d-flex align-items-center">
                <div className="legend-square range-r text-center">R</div>
                <div className="legend-description">Resistant</div>
            </div>


          </div>
          <div className="sm-legend-toggle">
            <Fa icon={this.state.legendOpen ? "chevron-down" : "chevron-up"}/>
          </div>
          <div className="legend-fade"></div>
        </div>
      )
    }
 }


export default SMLegendComponent;
