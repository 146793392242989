import React from 'react';
import BaseComponent from '../common/BaseComponent';
import { connect } from 'react-redux';
import { Container, Row, Col, TabPane, TabContent, Nav, NavItem, NavLink, Fa } from 'mdbreact';
import classnames from 'classnames';

class DiagnosisComponent extends BaseComponent {
  constructor(props) {
     super(props);
     this.state = {
       activeItemPills: 0
     };
   }

   togglePills = (index, priority) => {
    if (this.state.activePills !== index) {
      this.setState({
        activeItemPills: index
      });
    }
    gtag('event', 'tab', {
       'event_category': "Drug Recommendations (Line)",
       'event_label': priority
     })

  }

   listRecommendations(recommendations){
    return  _.orderBy(recommendations, 'priority', 'asc');
   }

   renderAlerts(diagnosisCard){
     var alerts = []
     if(diagnosisCard){
       diagnosisCard.recommendations.map(recommendation => {
         if(recommendation.drug){
           alerts = alerts.concat(recommendation.drug.alerts)
         }
       })
       if(alerts.length > 0){
         return alerts.map((alert, index) => {
           return(
             <p key={index}><i className="fa fa-warning mr-2 amber-text" aria-hidden="true"></i><strong>{alert && alert.alertText}</strong></p>
           )
         })
       }
     }
   }

   populateReference(referenceType, code){
     if(Number.isInteger(parseInt(code))){
       return code;
     }else{
       var referenceList = this.props.references
       if(referenceList){
         var referenceGroup = _.find(referenceList, { 'name': referenceType});
         var referenceObject = _.find(referenceGroup.list, { 'code': code });
         if(referenceObject === undefined){
           var otherObject = {}
           otherObject.typeName = code
           otherObject.type = "other"
           referenceObject = otherObject
         }
         return referenceObject
       }
     }
   }

   dosageString(dosing){
     var dosingString = "";
     var frequency = "";
     if(dosing.dosage != "undefined"){
       var dosage = dosing.dosage.dose + ' ' + this.populateReference('Dose Unit', dosing.dosage.unit).typeName;
       if(dosing.frequency){
         frequency = this.populateReference('Frequency Units', dosing.frequency.unit).typeName;
       }
       dosingString = dosage + ' ' + frequency;
     }
     return dosingString;
   }

  render(){
    const diagnosisCard = this.props.diagnosisCard
    return(
      <div className="page-content">
        <div className="content-title">
          <h4>{diagnosisCard.diagnosis && diagnosisCard.diagnosis.name}</h4>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-group mt-2 mt-md-4">
              <li className="list-group-item list-group-title">Common Pathogens</li>
              {diagnosisCard.bacterias && diagnosisCard.bacterias.map(bacteria => {
                return(
                  <li key={bacteria._id} className="list-group-item">{bacteria.name}</li>
                )
              })}
            </ul>
          </div>
          <div className="col-md-6">
            <ul className="list-group mt-2 mt-md-4">
              <li className="list-group-item list-group-title">Comments & Alerts</li>
              <li className="list-group-item">
                <p>{diagnosisCard.comment && diagnosisCard.comment}</p>
                {diagnosisCard.recommendations && this.renderAlerts(diagnosisCard)}
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-2 mt-md-4 recommendation-section">
          <div className="col-sm-12">
            <ul className="list-group">
              <li className="list-group-item list-group-title">Recommendations</li>
              <li className="list-group-item">
                <Container className="my-0 my-md-4 p-0">
                  <Nav color="secondary" className="nav-justified ">
                    {diagnosisCard.recommendations && this.listRecommendations(diagnosisCard.recommendations).map((recommendation, index) => {
                      return(
                        <NavItem key={index} className={(recommendation.priority == "1") ? "first-line" : (recommendation.priority == "2") ? "second-line" : "third-line"}>
                          <NavLink to="#" className={classnames({ active: this.state.activeItemPills == index })} onClick={() => { this.togglePills(index, recommendation.priority); }}>
                            {(recommendation.priority == "1") ? "First Line" : (recommendation.priority == "2") ? "Second Line" : "Third Line"}
                          </NavLink>
                        </NavItem>
                      )
                    })
                    }
                  </Nav>
                  <TabContent activeItem={this.state.activeItemPills} className={(this.state.activeItemPills == "0") ? "first-line" : (this.state.activeItemPills == "1") ? "second-line" : "third-line"}>
                    {diagnosisCard.recommendations && this.listRecommendations(diagnosisCard.recommendations).map((recommendation, index) => {
                      return(
                        <TabPane key={index} tabId={index}>
                          <div className="row">
                            <div className="col-12 col-md-4 reco-section">
                              <div className="reco-section-title">Drug:<div className="max-dose-disclaimer">See Dosing Quick References</div></div>

                              {recommendation.drug && recommendation.drug.name}

                              {recommendation.drug ?
                                <div key={index} className="mt-2">{recommendation.drug.dosings && recommendation.drug.dosings.dosingItems.map((dosingItem, index) => {
                                    return(
                                      <div key={index}>
                                        <div className="mt-3">{dosingItem.routeOfAdministration}</div>
                                        <ul>
                                          {dosingItem.dosings.map((dosing, index) => {
                                            return(
                                              <li key={index}>{this.dosageString(dosing)}</li>
                                            )
                                          })}
                                        </ul>
                                      </div>
                                    )
                                  })}
                                </div>
                                :
                                <div className="mt-2">{recommendation.customDrug}</div>
                              }



                              {/* {recommendation.drug && recommendation.drug.dosings.length > 0 && recommendation.drug.dosings.map((dosing, index) => {
                                dosing.dosingItems = _.chain(dosing.dosingItems).groupBy(function(o){return o.routeOfAdministration}).map(function(dosingItems, i){
                                  return{
                                    routeOfAdministration: i,
                                    dosings: dosingItems
                                  }
                                }).value()
                                return(
                                  <div key={index} className="mt-2">{dosing.dosingItems.map((dosingItem, index) => {
                                      return(
                                        <div key={index}>
                                          <div>{dosingItem.routeOfAdministration}</div>
                                          <ul>
                                            {dosingItem.dosings.map((dosing, index) => {
                                              return(
                                                <li key={index}>{this.dosageString(dosing)}</li>
                                              )
                                            })}
                                          </ul>
                                        </div>
                                      )
                                    })}
                                  </div>
                                )
                              })} */}

                            </div>
                            <div className="col-12 col-md-4 reco-section divider-left"><div className="reco-section-title">Duration:</div>{recommendation.duration && recommendation.duration}</div>
                            <div className="col-12 col-md-4 reco-section divider-left"><div className="reco-section-title">Comment:</div>{recommendation.comment && recommendation.comment}</div>
                          </div>
                        </TabPane>
                      )
                    })
                    }
                  </TabContent>
                </Container>
              </li>
              {/* {diagnosisCard.recommendations && this.listRecommendations(diagnosisCard.recommendations).map(recommendation => {
                return(
                  <li key={recommendation.priority} className="list-group-item">
                    <ul className="list-group">
                      <li className="list-group-item list-group-title">{(recommendation.priority == "1") ? "First Line" : (recommendation.priority == "2") ? "Second Line" : "Third Line"}</li>
                      <li className="list-group-item">

                        <div className="row">
                          <div className="col-4"><strong>Drug:</strong> {recommendation.drug && recommendation.drug.name}</div>
                          <div className="col-4"><strong>Duration:</strong> {recommendation.duration && recommendation.duration}</div>
                          <div className="col-4"><strong>Comment:</strong> {recommendation.comment && recommendation.comment}</div>
                        </div>
                      </li>
                    </ul>
                  </li>
                )
              })
              } */}
            </ul>
          </div>
        </div>


      </div>
    )
  }
}

function mapStateToProps(state){
  return{
    references: state.configRefState.configRef
  }
}

export default connect(mapStateToProps)(DiagnosisComponent)
