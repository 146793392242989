import * as ActionTypes from './action-types';
import * as diagnoseApi from '../api/diagnosis-api';


export function getDiagnoseList() {
  return function(dispatch) {
      return diagnoseApi.getDiagnoseList()
      .then((diagnoseList) => {
        dispatch({type: ActionTypes.GET_DIAGNOSESLIST_SUCCESS, diagnoseList});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function getDiagnosesByGroupName() {
  return function(dispatch) {
      return diagnoseApi.getDiagnosesByGroupName()
      .then((diagnoseByGroupNameList) => {
        dispatch({type: ActionTypes.GET_DIAGNOSES_BY_GROUPNAME_LIST_SUCCESS, diagnoseByGroupNameList});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function getDiagnoseById(diagnoseId) {
  return function(dispatch) {
    return diagnoseApi.getDiagnoseById(diagnoseId)
    .then((diagnose) => {
      dispatch({type: ActionTypes.GET_DIAGNOSEBYID_SUCCESS, diagnose});
    })
    .catch(error => {
      throw(error);
    })
  }
}

export function getCurrentDiagnosisCard(diagnoseId) {
   return function(dispatch) {
     return diagnoseApi.getCurrentDiagnosisCard(diagnoseId)
     .then((diagnosisCard) => {
       dispatch({type: ActionTypes.GET_CURRENT_DIAGNOSISCARD_SUCCESS, diagnosisCard});
     })
     .catch(error => {
       throw(error);
     })
   }
 }
