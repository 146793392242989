import * as types from '../actions/action-types';
import initialState from './initialState';
import _ from 'lodash';

const diagnosisReducer = function(state = initialState, action) {

  switch(action.type) {

    case types.GET_DIAGNOSESLIST_SUCCESS:
      return {...state, diagnoseList:action.diagnoseList};  //whatever our current state is, add on "diagnosesList"

    case types.GET_DIAGNOSEBYID_SUCCESS:
      return {...state, diagnose:action.diagnose};  //whatever our current state is, add on "diagnose"

    case types.GET_DIAGNOSES_BY_GROUPNAME_LIST_SUCCESS:

      var sortedList = []

      // sort diagnoses within groups alphabetically
      _.each(action.diagnoseByGroupNameList, function(group) {
        group.diagnoses = _.sortBy(group.diagnoses, [function(o){
          return o.name
        }])
        sortedList.push(group)
      });

      return {...state, diagnoseByGroupNameList:action.diagnoseByGroupNameList};  //whatever our current state is, add on "diagnose"

    case types.GET_DIAGNOSE_SUCCESS:
      return {...state, diagnose:action.diagnose};  //whatever our current state is, add on "diagnose"

    case types.GET_DIAGNOSISCARD_SUCCESS:
        return {...state, diagnosisCard:action.diagnosisCard};  //whatever our current state is, add on "diagnose"

    case types.GET_ALL_DIAGNOSISCARDS_SUCCESS:
            return {...state, diagnosisCards:action.diagnosisCards};

    case types.GET_CURRENT_DIAGNOSISCARD_SUCCESS:
    // debugger;
      action.diagnosisCard.recommendations.map(recommendation => {

        if(recommendation.drug){
          recommendation.drug.dosings.map(dosing => {
            dosing.dosingItems = _.chain(dosing.dosingItems).groupBy(function(o){return o.routeOfAdministration}).map(function(dosingItems, i){
              return{
                routeOfAdministration: i,
                dosings: dosingItems
              }
            }).value()
          })
          recommendation.drug.dosings = recommendation.drug.dosings[0];
        }
      })
      // debugger;
      return {...state, diagnosisCard:action.diagnosisCard};

    case types.GET_LASTPUBLISHED_DIAGNOSISCARD_SUCCESS:
          // action.diagnosisCard.recommendations.map(recommendation => {
          //   recommendation.drug.dosings = [recommendation.drug.dosings[0]]
          // })
          return {...state, lastPublishedDiagnosisCard:action.diagnosisCard};

  }
  return state;
}

export default diagnosisReducer
