import React from 'react';
import BaseComponent from '../common/BaseComponent';
import { Container, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'mdbreact';


class RenalDosingModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    gtag('event', 'click', {
       'event_category': "Modal",
       'event_label': "Renal Dosing"
     })
  }

  render() {
    return (
      <Container-Fluid>
        {/* <span className="small-link" onClick={this.toggle}>GFR Equation</span> */}
        {/* <i className="fa fa-plus-square green-text ml-2 pointer" onClick={this.toggle}></i> */}
        {/* <Button color="danger" onClick={this.toggle}>Modal</Button> */}
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" id="renal-dosing-modal">
          <ModalHeader toggle={this.toggle}>Renal Dosing</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <ul className="list-group">
                  <li className="list-group-item list-group-title">
                    PEDIATRIC estimated renal function (&#62;1yr to &#60;18yr)
                    <span className="table-subtitle">Use modified (Bedside) Schwarts Equation</span>
                  </li>
                  <li className="list-group-item">
                    <p>eGFR/Clcr (mL/min/1.73m2) = (0.41 x height in cm) / Scr (mg/dL)</p>
                    <p>NOTE: BCH laboratory measures creatinine with calibration traceable to an IDMS reference measurement prodcedure. Using the original Schwartz equation with a creatinine value from a method with calibration traceable to IDMS will overestimate GFR by 20-40%. Therefore it is very important to use the Bedside IDMS-traceable Schwartz GFR calculation since our institution is using creatinine methodes calibrated to be the IDMS traceable.</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row top-buffer">
              <div className="col-12">
                <ul className="list-group">
                  <li className="list-group-item list-group-title">
                    ADULT estimated renal function (&#62;=18yr)
                    <span className="table-subtitle">Use Cockcroft-Gault Equation</span>
                  </li>
                  <li className="list-group-item">
                    <p>Male: Clcr (mL/minute) = [(140 - age) x weight (kg)] / (72 x Scr)</p>
                    <p>Female: Clcr (mL/minute) = Male x 0.85</p>
                  </li>
                </ul>
              </div>
            </div>
            {/* <table className="table table-bordered">
              <thead>
                <tr align="center">
                  <th>Drug</th>
                  <th>Dosing Interval</th>
                  <th>Through (mcg/ml)<span className="table-subtitle">(30 min prior to dose)</span></th>
                  <th>Peak (mcg/ml)<span className="table-subtitle">(30 min post end of 30 min infusion or 60 min post end of 60 min infusion)</span></th>
                </tr>
              </thead>
              <tbody>
                <tr align="center" title="row-group-odd">
                  <td rowSpan="4" valign="middle">Amikacin</td>
                  <td>Q8h</td>
                  <td>&#60; 10</td>
                  <td>15 - 30</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>CF: Q8h</td>
                  <td>&#60; 10</td>
                  <td>25 - 40</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>CF: Q8h</td>
                  <td>&#60; 10</td>
                  <td>60 - 100</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>CF: Q8h</td>
                  <td>&#60; 10</td>
                  <td>80 - 120</td>
                </tr>

                <tr align="center" title="row-group-even">
                  <td>Chloramphericol</td>
                  <td>Usual Dosing</td>
                  <td>5 - 15</td>
                  <td>15 - 25</td>
                </tr>

                <tr align="center" title="row-group-odd">
                  <td rowSpan="5">Gentamicin or Tobramycin</td>
                  <td>Neonates</td>
                  <td>&#60;= 1 - 1.5</td>
                  <td>6 - 12</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>Q8h</td>
                  <td>&#60; 2</td>
                  <td>4 - 10</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>CF: Q8h</td>
                  <td>&#60; 2</td>
                  <td>8 - 12</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>Q24h</td>
                  <td>&#60; 1</td>
                  <td>15 - 25</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>CF: Q24h</td>
                  <td>&#60; 1</td>
                  <td>20 - 40</td>
                </tr>

                <tr align="center" title="row-group-even">
                  <td>Chloramphericol</td>
                  <td>Usual Dosing</td>
                  <td>10 - 20</td>
                  <td>N/A</td>
                </tr>
              </tbody>
            </table> */}
          </ModalBody>
          {/* <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Close</Button>{' '}
          </ModalFooter> */}
        </Modal>
      </Container-Fluid>
    );
  }
}

export default RenalDosingModal;
