import * as types from '../actions/action-types';
import initialState from './initialState';
import _ from 'lodash';

const clientReducer = function(state = initialState, action) {

  switch(action.type) {

    case types.GET_BUGSDRUGSDIAGNOSES_SUCCESS:
    let [bacteria, drug, diagnosis] = action.searchList
      console.log("BUGSDRUGSDIAGNOSES List FROM CLIENT REDUCER", action.searchList)
      drug.objects = _.uniqBy(drug.objects, "name")
      if(drug == "n"){
        console.log("STRING N OBJECT", drug)
      }
      return {...state, bugsDrugsDiagnoses:action.searchList};  //whatever our current state is, add on "diagnosesList"

    case types.GET_ALLALERTS_SUCCESS:
      return {...state, alertsList:action.alertsList};

    case types.UPDATE_NAV_LOCATION:
      return {...state, navLocation:action.navLocation}

  }
  return state;
}

export default clientReducer
