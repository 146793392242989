import React from 'react';
import BaseComponent from '../common/BaseComponent';

class ContactComponent extends BaseComponent {
  constructor(props) {
     super(props);
     this.state = {
     };
   }

   componentDidMount(){
   }

   componentWillMount(){
   }

  render(){
    return(
        <div className="page-content" id="contact-view">
          <div className="row">
            <div className="col-12 bottom-margin">
              <ul className="list-group">
                <li className="list-group-item">
                  <h5 className="contact-text">For questions or support, fill out the form below or email tech support at<br/><a className="email-link" href="mailto:innovation-dl@childrens.harvard.edu">innovation-dl@childrens.harvard.edu</a>.</h5>
                  <div className="feedback-form">
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfboUm3Or1sGybbZO93Zmdjoojm2Oh-fRmTYmav8X_whurBYA/viewform?embedded=true" frameBorder="0" marginHeight="0" marginWidth="0">Loading...</iframe>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
    )
  }
}


export default ContactComponent;
