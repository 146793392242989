import * as types from '../actions/action-types';
import initialState from './initialState';
import _ from 'lodash';

const susceptibilityMatrixReducer = function(state = initialState, action) {

  switch(action.type) {

    case types.GET_CURRENTSM_SUCCESS:
      return {...state, currentSM:action.publishedSM};  //whatever our current state is, add on "SMList"

    case types.GET_LATESTPUBLISHEDSM_SUCCESS:
      var drugs = [];
      var bugs = [];
      action.latestPublishedSM[0].lineItems.map(lineItem => {
        drugs.push(lineItem.drug)
        bugs.push(lineItem.bacteria)
      })

      drugs = _.uniqBy(drugs, "_id")
      drugs = _.orderBy(drugs, 'name')

      bugs = _.uniqBy(bugs, "_id")
      bugs = _.orderBy(bugs, 'name')

      return {...state, SM:action.latestPublishedSM[0], SMDrugs:drugs, SMBugs:bugs};  //whatever our current state is, add on "SMList"

  }
  return state;
}

export default susceptibilityMatrixReducer
