import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import SubPageHeaderComponent from '../components/common/SubPageHeaderComponent';
import ContactComponent from '../components/Contact/ContactComponent';
import * as Enums from '../enums';

class ContactView extends BaseComponent {

  componentDidMount(){
    gtag('event', 'view', {
       'event_category': "Page View",
       'event_label': "Feedback"
     })
  }

  render(){
    return(
      <div>
        <SubPageHeaderComponent title={Enums.CONTACT}/>
        <ContactComponent/>
      </div>

    )
  }
}

export default ContactView;
