import axios from 'axios';

function preview(){
  if(sessionStorage.getItem('preview') == "true"){
    return "?preview=true"
  }else{
    return ""
  }
}

export function getBugsDrugsDiagnoses() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'client/getAllBugDrugDiagnosis'+preview())
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function getAllAlerts() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'alerts'+preview())
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}
