import React from 'react'
import { Link, browserHistory, hashHistory } from 'react-router'


class BaseComponent extends React.Component {

  componentDidMount() {
    //console.log("BaseComponent didmount")
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("BaseComponent didupdate")
  }

}

export default BaseComponent
