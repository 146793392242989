import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, actions, reset, change, arrayPush } from 'redux-form';
import { Link, browserHistory, hashHistory } from 'react-router';
import SMLegendComponent from './SMLegendComponent';
import {bindActionCreators} from 'redux';
import BaseComponent from '../common/BaseComponent';
import $ from 'jquery'

class SMDisplayComponent extends BaseComponent {

    constructor(props) {
        super(props)
    }

    componentDidMount(){
      //this.props.SMActions_binded.getSMById(this.props.params.SMId)
    }

    // this method creates array containing array. Each inner array is a row that can be displayed directly into table
    // [
    //   [ 'Gram Positive', 'drug1', 'drug2', 'drug3', 'drug4' ],
    //   [ 'bug1', 50 ],
    //   [ 'bug2', , 60, 95 ],
    //   [ 'bug3', , , , 85 ]
    // ]

    highlightcolumn(e, i){
      var styleOutput = $(e.target).closest('table').find("tbody > tr td:nth-child(" + i + ")").css('border-style')
      if($(e.target).closest('table').find("tbody > tr td:nth-child(" + i + ")").css('border-style') == "dotted solid"){
        var table = $(e.target).closest('table').find("tbody > tr td:nth-child(" + i + ")").css({"border":"0"})
        console.log(e.target)
      }else{
        console.log(styleOutput)
        var table = $(e.target).closest('table').find("tbody > tr td:nth-child(" + i + ")").css({"border-color":"black", "border-width":"2px 5px", "border-style":"dotted solid"})
        console.log(e.target)
      }

      // $(e.target).closest('table').css("border", "solid black 5px");
      console.log($(this))
      // $('td')
    }

    highlightrow(e, i){
      // var styleOutput = $(e.target).closest('table').find("tbody > tr:nth-child(" + i + ")").css({"border-color":"black", "border-width":"2px 5px", "border-style":"dotted solid"})

      if($(e.target).closest('table').find("tbody > tr:nth-child(" + i + ")").css('border-style') == "solid"){
        var table = $(e.target).closest('table').find("tbody > tr:nth-child(" + i + ")").css({"border":"0"})
        console.log(e.target)
      }else{
        var table = $(e.target).closest('table').find("tbody > tr:nth-child(" + i + ")").css({"border-color":"black", "border-width":"5px", "border-style":"solid"})
        console.log(e.target)
      }
    }

    createMatrixRows(data, matrixName, uniqueAlphbeticalDrugArray, sourceType){
      var result = [[matrixName]];
      var row={}
      var col={}

      result[0].push("Isolates")
      //create a spot for each drug in result[0] HEADER array.
      for(var i = 0; i < uniqueAlphbeticalDrugArray.length; i++){
        result[0].push(uniqueAlphbeticalDrugArray[i])
      }
      result.push(sourceType)

      data.forEach(function (lineItem) {
          //Creates result as 2 dimensional array [0][0], [0][1]
          var bacteriaKey = lineItem.bacteria.name //+ "#$#" +lineItem._id //commented part is needed if we want multiple rows per bacteria
          if (!(bacteriaKey in row)) {
              //row[a.to] - add dynamic key using square bracket.allows to access properties dynamically
              //Entire line Will output row as {salmonella:1}, Also result will have new row array if not present
              row[bacteriaKey] = result.push([bacteriaKey]) - 1;
          }
          var drugKey = lineItem.drug.name
          ///var drugKey = lineItem.drug.name + "#$#" +lineItem._id - add if duplicate drugs are needed. Also, createHeaderRow() will need update
          if (!(drugKey in col)) {
              //col[a.from] - add dynamic key using square bracket.
              //Entire line Will output row as {amox:1}, Also result[0] (which is first row) will have new row if not present
              //col[drugKey] = result[0].push(drugKey) - 1;

              //Find index of the drug key
              var index =uniqueAlphbeticalDrugArray.indexOf(drugKey)
              //result[0].splice(index + 1, 1, drugKey)//insert item into atrray at specified position (thats why we created fake spot). Second parameter of splice is replace how many elements in the array
              col[drugKey] = index + 1 + 1;
          }
          //console.log(result);console.log(row);console.log(col);//console.log("row=" + row[a.to]);console.log("column=" + col[a.from]);
          var resVal ="";
          if(lineItem.resistanceValue){
            resVal = lineItem.resistanceValue
          }
          //get max
          // if(lineItem.isolates && (!result[row[bacteriaKey]][1] || parseInt(result[row[bacteriaKey]][1]) < parseInt(lineItem.isolates))){
          //   result[row[bacteriaKey]][1] = lineItem.isolates;
          // }
          //display all unique isolate counts
          if(lineItem.isolates && !result[row[bacteriaKey]][1]){
            result[row[bacteriaKey]][1] = lineItem.isolates;
          }
          if(lineItem.isolates && result[row[bacteriaKey]][1] 
            && result[row[bacteriaKey]][1].split(", ").indexOf(lineItem.isolates) == -1
            ){
            result[row[bacteriaKey]][1] = result[row[bacteriaKey]][1] + ", " + lineItem.isolates;
          }
          result[row[bacteriaKey]][col[drugKey]] = resVal + "#$#" + lineItem.recommended; //row[a.to] gets value of row.jeff , which is 1, adds in result 2 dimensioanl array the ratings.square bracket.allows to access properties dynamically
          //console.log(result);console.log("------");
      });
      return result;
    }

    createMatrixForBacteriaType(data, matrixName, categoryCode, sourceTypes, sourceTypeLabels){
      var uniqueAlphbeticalDrugArray = [];
      var final_Result = [];
      var lineItemList = [];

      var bugDrugCombos = [];

      for(var k in sourceTypes){
        //get all Gram positive line items which has cultureSorce as blood
        bugDrugCombos[k] = _.filter(this.props.SusMa.lineItems, {bacteria : {category:categoryCode}, cultureSource:sourceTypes[k]})
        //order by bacteria name, caseinsensitive
        bugDrugCombos[k] = _.orderBy(bugDrugCombos[k], [function (item) { return item.bacteria.name.toLowerCase(); }], ["asc"]);
        bugDrugCombos[k].forEach(function (item) {
          lineItemList.push(item)
        })
      }

      //create alphabetical list of unique drugs
      lineItemList.forEach(function (lineItem) {
        uniqueAlphbeticalDrugArray.push(lineItem.drug.name)
      })
      //using ES6 'set' feature to get unique drug names
      var uniqueAlphbeticalDrugArray = [...new Set(uniqueAlphbeticalDrugArray)]
      //sort unique drug array alphabetically case-insesitive
      uniqueAlphbeticalDrugArray.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
      });

      for(var k in sourceTypeLabels){
        //create matrix for negative_blood data set
        var result = this.createMatrixRows(bugDrugCombos[k], matrixName, uniqueAlphbeticalDrugArray, sourceTypeLabels[k]) //returns array containing array
        result.forEach(function (item, index) {
          if(k==0 || index > 0){ //skip the generated header row.
              final_Result.push(item)
          }
        })
      }

      return {result:final_Result};
    }

    createHeaderRow(resultRowsArr){
        return resultRowsArr[0].map((cell,index) => {
          let columnNumber = index + 1
          var buffer = [];
          if(index === 0){ //first cell needs special class
              buffer.push(
                <th key={index} className="row-header col-md-3">
                  {cell}
                </th>
              )
          }
          else{
            if(cell.length > 32){ //some number that matches our table structure for test wrap in header
              var cellArr = cell.match(/.{1,32}/g);
              buffer.push(<th key={index} className={this.props.HeaderRotate ? "rotate-45" : " "}><div><span><input className="mr-2 sm-checkbox" onClick={(e) => {this.highlightcolumn(e, columnNumber)}} type="checkbox" name="name" value="value"/>{cellArr[0]}<br></br>{cellArr[1]}</span></div></th>)
            }
            else{
              buffer.push(<th key={index} className={this.props.HeaderRotate ? "rotate-45" : " "}><div><span><input className="mr-2 sm-checkbox" onClick={(e) => {this.highlightcolumn(e, columnNumber)}} type="checkbox" name="name" value="value"/>{cell}</span></div></th>)
            }
          }
          return (buffer)
        })
    }

    createNonHeaderRows(totalColumns,rowArr,index){
        var buffer = [];
        if(index !=0){ //skip the header row, we have already processed it
          for(var i = 0; i < totalColumns; i++){
            let rowNumber = index + 1
              var cellVal = rowArr[i];
                if(cellVal){
                  var cellArray = cellVal.split("#$#")
                  var cssClassContent = getCSSClassAndContent(cellArray[0]);
                  if(i == 0){ //bug names
                    buffer.push(<td key={rowArr[0]+ i}><input className="mr-2 sm-checkbox" onClick={(e) => {this.highlightrow(e, rowNumber)}} type="checkbox" name="name" value="value"/> {cellArray[0]}</td>)
                  }else if(i == 1){
                    var cssClass = "" // cssClassContent.cssClass
                    cssClass = cssClass + " text-center"
                    buffer.push(<td className={cssClass} key={rowArr[0]+ i}><span className="content">{cellArray[0]}</span></td>)
                  }else{
                    var cssClass = cssClassContent.cssClass
                    if(cellArray[1]=="YES"){//recommended=yes
                      cssClass = cssClass + " recommended  text-center"
                      buffer.push(<td className={cssClass} key={rowArr[0]+ i}><span className="content">{cssClassContent.content}</span></td>)
                      //buffer.push(<td className="range1" key={rowArr[0]+ i}><span className="white">{cellArray[0]}</span></td>)
                    }else{
                      cssClass = cssClass + " text-center"
                      buffer.push(<td className={cssClass} key={rowArr[0]+ i}><span className="content">{cssClassContent.content}</span></td>)
                    }
                  }
                }else{
                  buffer.push(<td className="text-center" key={rowArr[0]+ i}><span></span></td>)
                }
            }
        }
        return (buffer)
    }

    createSeparatorRow(totalColumns,text){
        var buffer = [];
        //buffer.push(<td key={{text}} className="bg-info" colSpan={totalColumns}><b>{text}</b></td>)

        for(var i = 0; i < totalColumns; i++){
            if(i===0){
              buffer.push(<td key={{text}+ i} className="sm-separator">{text}</td>)
            }else{
              buffer.push(<td align="center" key={{text}+ i} className="sm-separator"></td>)
            }
        }
        return (buffer)
    }

    createSMSectionForBacteriaType(matrixName, categoryCode, sourceTypes, sourceTypeLabels){
      var resultRowsArr_obj = this.createMatrixForBacteriaType(this.props.SusMa.lineItem, matrixName, categoryCode, sourceTypes, sourceTypeLabels)
      var resultRowsArr = resultRowsArr_obj.result
      var totalColumns = parseInt(resultRowsArr[0].length) //topmost row has exact number of columns

      return(
        <div className="table-responsive sectionSM">
          <table className="table table-header-rotated table-striped table-hover table-striped-column">
              <thead>
                <tr>
                  {this.createHeaderRow(resultRowsArr)}
                </tr>
              </thead>
              <tbody>
                {
                  resultRowsArr && resultRowsArr.map((rowArr,index) => {
                    var buffer = [];
                    if(!Array.isArray(rowArr)){
                      var separatorRow = this.createSeparatorRow(totalColumns, rowArr)
                      separatorRow=<tr key={"source_"+{index}}>{separatorRow}</tr>
                      buffer.push(separatorRow)
                    }else{
                    var row = this.createNonHeaderRows(totalColumns, rowArr, index)
                    row = <tr key={index}>{row}</tr>
                    buffer.push(row)
                    }
                  return(buffer)
                  })
                }
              </tbody>
          </table>
        </div>
      )
    }

    render() {
      return(
          <div>
          {/*ROW 3*/}
          <div id="SMPreviewForm" className="row hideSMPreview">{/* row*/}
              <div className="col-md-12">{/* ol-md-12*/}
                <div className="panel panel-default"> {/* panel-default*/}
                    <div className="panel-body">{/* panel-body*/}
                      {
                        this.createSMSectionForBacteriaType('Gram-Negative Bacteria: % Susceptible', 'AEROBIC_GRAM_NEG', ['ALLSRCEXCEPT_OUTPATIENT_URINE','OUTPATIENT_URINE','NOT_TESTED_AT_BCH'], ['All Sources Except Outpatient Urine','Outpatient Urine','NOT TESTED AT BCH (Expected susceptibilities shown)'])
                      }
                      {
                        this.createSMSectionForBacteriaType('Gram-Positive Bacteria: % Susceptible', 'AEROBIC_GRAM_POS', ['ALLSRCEXCEPT','NOT_TESTED_AT_BCH'], ['All Sources','NOT TESTED AT BCH (Expected susceptibilities shown)'])
                      }
                      {
                        this.createSMSectionForBacteriaType('Anaerobic Bacteria', 'ANAEROBIC', ['NOT_TESTED_AT_BCH'], ['NOT TESTED AT BCH (Expected susceptibilities shown)'])
                      }
                      {
                        this.createSMSectionForBacteriaType('Atypical Bacteria', 'ATYPICAL', ['NOT_TESTED_AT_BCH'], ['NOT TESTED AT BCH (Expected susceptibilities shown)'])
                      }

                  </div>{/* panel-body*/}
              </div> {/* panel-default*/}
           </div>{/* col-md-12*/}
        </div>{/* row*/}
      {/*ROW 3*/}
      </div>
      )
    }
 }

 function isNumeric(n) {
   return !isNaN(parseFloat(n)) && isFinite(n);
 }

 function getCSSClassAndContent(resistanceVal){
     var cssClass="";
     var content="";
     if(isNumeric(resistanceVal)){
       content = resistanceVal;
       if(resistanceVal >=90){
         cssClass = "range1"
       }else if(resistanceVal >=80 && resistanceVal <=89){
         cssClass = "range2"
       }else if(resistanceVal >=70 && resistanceVal <=79){
         cssClass = "range3"
       }else{
         cssClass="range4"
       }
     }else{  ///TBD- Chnage following values as per backend
       if(resistanceVal ==="SUSCEPTIBLE"){
         cssClass = "range1";
         content="S"
       }else if(resistanceVal ==="RESISTANT"){
         cssClass = "range-r"
         content="R"
       }else if(resistanceVal ==="NOT_TESTED"){
         cssClass = "range-nottested"
         content="-"
       }else if(resistanceVal ==="INTERMEDIATE"){
         cssClass = "intermediate"
         content="S/R"
       }

     }
     return {cssClass:cssClass,content:content}
 }

export default SMDisplayComponent;
