import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import SubPageHeaderComponent from '../components/common/SubPageHeaderComponent';
import DosingGuidelinesComponent from '../components/DosingGuideline/DosingGuidelinesComponent';
import DosingGuidelineComponent from '../components/DosingGuideline/DosingGuidelineComponent';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import { Tooltip } from 'mdbreact';
import * as DosingActions from '../actions/drugdosage-actions';
import * as SMActions from '../actions/susceptibilityMatrix-actions';
import * as Enums from '../enums';

class DosingGuidelinesView extends BaseComponent {

  constructor(props){
    super(props)
    this.state = {
    }
  }

  componentDidMount(){
    gtag('event', 'view', {
      'event_category': "Page View",
      'event_label': "Antibiotic Recommendations"
    })
    this.props.dosingActions.getAllDosings()
    this.props.SMActions.getLatestPublishedSM();
  }

  drugInMatrix(drugId){
    var drug = _.find(this.props.SMDrugs, function(o) { return o._id == drugId });
    if(drug){
      return true
    }else{
      return false
    }
  }

  render(){
    return(
      <div>
        <SubPageHeaderComponent title={Enums.DOSING_GUIDELINES}/>
        <div className="cost-legend-top">
          <div className="cost-legend-top-value"><span className="cost-legend-top-icon">$: </span>$1-$4</div>
          <div className="cost-legend-top-value"><span className="cost-legend-top-icon">$$: </span>$5-$10</div>
          <div className="cost-legend-top-value"><span className="cost-legend-top-icon">$$$: </span>$11-$45</div>
          <div className="cost-legend-top-value"><span className="cost-legend-top-icon">$$$$: </span>&#8805;$46</div>
          {/* <Tooltip placement="bottom" componentClass="mt-2 mt-md-0 special-cons-btn cursor-default" tag="div" tooltipContent="Yellow"</Tooltip> */}
          <Tooltip
            placement="bottom"
            componentClass="cursor-default cost-info"
            tag="span"
            tooltipContent="BCH cost is based on 1 day of therapy for a 15kg (0.6m2) patient">
          </Tooltip>
          {/* <div className="cost-legend-top-value"><Tooltip placement="bottom" componentClass="" tag="span" tooltipContent="s"</Tooltip></div> */}
        </div>
          <div className="page-content" id="dosing-guidelines-view">
            {this.props.dosingsList && this.props.dosingsList.length > 0 && this.props.dosingsList.map((dosing, index) => {
              return(
                <div key={index} className="single-dosing card my-4 p-2 p-sm-4">
                  <DosingGuidelineComponent  drugDosage={dosing} showMatrixButton={this.drugInMatrix(dosing.drug._id)} renderedFromList={true}/>
                </div>
              )
            })}
          </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return{
    dosingsList: state.drugState.drugDosings,
    SMDrugs: state.susceptibilityMatrixState.SMDrugs,
    references: state.configRefState.configRef
  }
}

function mapDispatchToProps(dispatch){
  return{
    dosingActions: bindActionCreators(DosingActions, dispatch),
    SMActions: bindActionCreators(SMActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DosingGuidelinesView)
