import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import SubPageHeaderComponent from '../components/common/SubPageHeaderComponent';
import { Button, Popover, PopoverBody, PopoverHeader, Collapse } from 'mdbreact';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as DiagnosisActions from '../actions/diagnosis-actions';
import * as SMActions from '../actions/susceptibilityMatrix-actions';
import { Switch, Route, NavLink } from 'react-router-dom'
import DiagnosisComponent from '../components/DiagnosisRecommendation/DiagnosisComponent'
import SMDisplayComponent from '../components/SusceptibilityMatrix/SMDisplayComponent'
import SMLegendComponent from '../components/SusceptibilityMatrix/SMLegendComponent'
import _ from 'lodash';
import { Link } from 'react-router-dom'
import * as Enums from '../enums';

class DiagnosisView extends BaseComponent {

      constructor(props) {
        super(props)
      this.state = {
      };
    }

    componentDidMount(){
      this.props.diagnosisActions.getCurrentDiagnosisCard(this.props.match.params.diagnosisId)
      if(!this.props.SM.lineItems){
        this.props.SMActions.getLatestPublishedSM()
      }
    }

    filterSMForDiagnosis = () =>{
      var diagnosisCard = _.clone(this.props.diagnosisCard)
      var bacteriaIds = [];
      if(diagnosisCard.bacterias && diagnosisCard.bacterias.length > 0){
        for(var i=0; diagnosisCard.bacterias.length > i; i++){
          bacteriaIds.push(diagnosisCard.bacterias[i]._id)
        }
      }
      var matrix = _.clone(this.props.SM)
      var lineItems = [];
      matrix.lineItems.map( lineItem => {
        if(diagnosisCard.bacterias && bacteriaIds.includes(lineItem.bacteria._id)){
          lineItems.push(lineItem)
        }
      })
      matrix.lineItems = lineItems
      return matrix
    }

    componentDidUpdate(nextProps){
      if(nextProps.match.params.diagnosisId != this.props.match.params.diagnosisId){
        this.props.diagnosisActions.getCurrentDiagnosisCard(this.props.match.params.diagnosisId)
      }
    }

    render(){
      if(this.props.diagnosisCard.diagnosis){
        gtag('event', 'view', {
          'event_category': "Diagnosis View",
          'event_label': this.props.diagnosisCard.diagnosis.name
        })
      }
      return(
        <div id="diagnosis-view">
          <SubPageHeaderComponent title={Enums.DIAGNOSIS}/>
          <div className="mt-2 mt-md-4"></div>
          {this.props.diagnosisCard.diagnosis &&
            <DiagnosisComponent diagnosisCard={this.props.diagnosisCard}/>
          }
          <div className="row mt-2 mt-md-4">
            <div className="col-md-12">
              <ul className="list-group">
                <li className="list-group-item">
                  <span className="subpageSM">
                    {/* <SMLegendComponent/> */}
                    {this.props.SM.lineItems &&
                      <SMDisplayComponent SusMa={this.filterSMForDiagnosis()} HeaderRotate={true}/>
                    }
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {/* <DiagRecoMatrixComponent diagRecoMatrix={this.props.diagRecoMatrix} diagnosisCard={this.props.diagnosisCard}/> */}
        </div>
      )
    }
}

function mapStateToProps(state){
  return{
    diagnosisCard: state.diagnoseState.diagnosisCard,
    diagRecoMatrix: state.susceptibilityMatrixState.CurrentSM,
    SM: state.susceptibilityMatrixState.SM
  }
}

function mapDispatchToProps(dispatch){
  return {
    diagnosisActions: bindActionCreators(DiagnosisActions, dispatch),
    SMActions: bindActionCreators(SMActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiagnosisView);
