import React from 'react';
import BaseComponent from '../common/BaseComponent';
import { Container, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'mdbreact';


class TDMModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    gtag('event', 'click', {
       'event_category': 'Modal',
       'event_label': "Therapeutic Drug Monitoring"
     })
  }

  render() {
    return (
      <Container-Fluid>
        <div className="box-shadow mt-2 mt-md-0 special-cons-btn pointer tdm" onClick={this.toggle}>Therapeutic Drug Monitoring</div>
        {/* <Button color="danger" onClick={this.toggle}>Modal</Button> */}
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="fluid" id="tdm-modal">
          <ModalHeader toggle={this.toggle}>Therapeutic Drug Monitoring</ModalHeader>
          <ModalBody>
            <table className="table table-bordered">
              <thead>
                <tr align="center">
                  <th>Drug</th>
                  <th>Dosing Interval</th>
                  <th>Through (mcg/ml)<span className="table-subtitle">(30 min prior to dose)</span></th>
                  <th>Peak (mcg/ml)<span className="table-subtitle">(30 min post end of 30 min infusion or 60 min post end of 60 min infusion)</span></th>
                </tr>
              </thead>
              <tbody>
                <tr align="center" title="row-group-odd">
                  <td rowSpan="4" valign="middle">Amikacin</td>
                  <td>Q8h</td>
                  <td>&#60; 10</td>
                  <td>15 - 30</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>CF: Q8h</td>
                  <td>&#60; 10</td>
                  <td>25 - 40</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>CF: Q8h</td>
                  <td>&#60; 10</td>
                  <td>60 - 100</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>CF: Q8h</td>
                  <td>&#60; 10</td>
                  <td>80 - 120</td>
                </tr>

                <tr align="center" title="row-group-even">
                  <td>Chloramphericol</td>
                  <td>Usual Dosing</td>
                  <td>5 - 15</td>
                  <td>15 - 25</td>
                </tr>

                <tr align="center" title="row-group-odd">
                  <td rowSpan="5">Gentamicin or Tobramycin</td>
                  <td>Neonates</td>
                  <td>&#60;= 1 - 1.5</td>
                  <td>6 - 12</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>Q8h</td>
                  <td>&#60; 2</td>
                  <td>4 - 10</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>CF: Q8h</td>
                  <td>&#60; 2</td>
                  <td>8 - 12</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>Q24h</td>
                  <td>&#60; 1</td>
                  <td>15 - 25</td>
                </tr>
                <tr align="center" title="row-group-odd">
                  <td>CF: Q24h</td>
                  <td>&#60; 1</td>
                  <td>20 - 40</td>
                </tr>

                <tr align="center" title="row-group-even">
                  <td>Chloramphericol</td>
                  <td>Usual Dosing</td>
                  <td>10 - 20</td>
                  <td>N/A</td>
                </tr>
              </tbody>
            </table>
          </ModalBody>
          {/* <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Close</Button>{' '}
          </ModalFooter> */}
        </Modal>
      </Container-Fluid>
    );
  }
}

export default TDMModal;
