import * as ActionTypes from './action-types';
import * as drugdosageApi from '../api/drugdosage-api';
import _ from 'lodash';

export function getAllDosings() {
  return function(dispatch) {
    return drugdosageApi.getAllDosings()
    .then((drugDosingsList) => {
      drugDosingsList = _.uniqBy(drugDosingsList, function (e) {return e.drug._id;});
      var drugDosings = [];
      drugDosingsList.forEach(function(drugDosing){
        drugDosing.dosingItems = _.chain(drugDosing.dosingItems).groupBy(function(o){return o.routeOfAdministration}).map(function(array, i){
          return{
            routeOfAdministration: i,
            dosings: array
          }
        }).value()
        drugDosings.push(drugDosing)
      })
      dispatch({type: ActionTypes.GET_DRUGDOSINGS_SUCCESS, drugDosings});
    })
    .catch(error => {
      throw(error);
    })
  }
}

export function loadDrugDosageInfo(drugDosage) {
  return function(dispatch){
    dispatch({type: ActionTypes.GET_DRUGDOSAGE_SUCCESS, drugDosage});
  }
}

export function getDosingByDrugById(drugId) {
  return function(dispatch) {
      return drugdosageApi.getDosingByDrugById(drugId)
      .then((drugDosing) => {
        drugDosing.dosingItems = _.chain(drugDosing.dosingItems).groupBy(function(o){return o.routeOfAdministration}).map(function(array, i){
          return{
            routeOfAdministration: i,
            dosings: array
          }
        }).value()
        dispatch({type: ActionTypes.GET_DRUGDOSING_SUCCESS, drugDosing});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function getlastPublishedDosingDrugById(drugId) {
  return function(dispatch) {
      return drugdosageApi.getlastPublishedDosingByDrugId(drugId)
      .then((lastPublishedDrugDosing) => {
        lastPublishedDrugDosing.dosingItems = _.chain(lastPublishedDrugDosing.dosingItems).groupBy(function(o){return o.routeOfAdministration}).map(function(array, i){
          return{
            routeOfAdministration: i,
            dosings: array
          }
        }).value()
        dispatch({type: ActionTypes.GETLASTPUBLISHED_DRUGDOSING_SUCCESS, lastPublishedDrugDosing});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function getAllDrugsWithDosings() {
  return function(dispatch) {
      return drugdosageApi.getAllDrugsWithDosings()
      .then((list) => {
        let drugsList = _.forEach(list, function(drugGroup) {
          drugGroup.dosingItems = _.chain(drugGroup.dosingItems).groupBy(function(o){return o.routeOfAdministration}).map(function(array, i){
            return{
              routeOfAdministration: i,
              dosings: array
            }
          }).value()
          // drugGroup.dosingItems = _.groupBy(drugGroup.dosingItems,'routeOfAdministration');
        });
        dispatch({type: ActionTypes.GETDRUGSWITHDOSING_DRUGDOSING_SUCCESS, drugsList});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function getFilteredDosings(checkedDrugIds){
  return function(dispatch) {
    return drugdosageApi.getAllDosings()
    .then((drugDosingsList) => {
      drugDosingsList = _.uniqBy(drugDosingsList, function (e) {return e.drug._id;});
      var drugDosings = [];
      drugDosingsList.forEach(function(drugDosing){
        drugDosing.dosingItems = _.chain(drugDosing.dosingItems).groupBy(function(o){return o.routeOfAdministration}).map(function(array, i){
          return{
            routeOfAdministration: i,
            dosings: array
          }
        }).value()
        drugDosings.push(drugDosing)
      })
      dispatch({type: ActionTypes.GET_FILTEREDDRUGDOSING_SUCCESS, drugDosings, checkedDrugIds})
    })
    .catch(error => {
      throw(error);
    })
  }
}
