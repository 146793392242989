import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import * as SMActions from '../actions/susceptibilityMatrix-actions';
import SubPageHeaderComponent from '../components/common/SubPageHeaderComponent';
import SusceptibilityMatrixComponent from '../components/SusceptibilityMatrix/SusceptibilityMatrixComponent';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import * as Enums from '../enums';

class SusceptibilityMatrixView extends BaseComponent {

  componentDidMount(){
    this.props.SMActions.getLatestPublishedSM();
    gtag('event', 'view', {
       'event_category': "Page View",
       'event_label': "Antibiotic Susceptibilities"
     })
  }

  render(){
    return(
      <div>
        <SubPageHeaderComponent title={Enums.SUSCEPTIBILITY_MATRIX}/>
        {this.props.SM._id &&
          <SusceptibilityMatrixComponent bugId={this.props.match.params.bacteriaId} drugId={this.props.match.params.drugId} path={this.props.match.url}/>
        }
        {/* <Switch>
          <Route path="/susceptibilityMatrix/bug/:bacteriaId" component={SusceptibilityMatrixComponent} />
          <Route path="/susceptibilityMatrix/drug/:drugId" component={SusceptibilityMatrixComponent} />
        </Switch> */}
      </div>
    )
  }
}

function mapStateToProps(state){
  return{
    SM: state.susceptibilityMatrixState.SM,
    SMDrugs: state.susceptibilityMatrixState.SMDrugs,
    SMBugs: state.susceptibilityMatrixState.SMBugs
  }
}

function mapDispatchToProps(dispatch){
  return{
    SMActions: bindActionCreators(SMActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SusceptibilityMatrixView);
