import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import SubPageHeaderComponent from '../components/common/SubPageHeaderComponent';
import DocumentsComponent from '../components/Documents/DocumentsComponent';
import * as Enums from '../enums';

class DocumentsView extends BaseComponent {

  componentDidMount(){
    gtag('event', 'view', {
       'event_category': "Page View",
       'event_label': "Resource materials"
     })
  }

  render(){
    return(
      <div>
        <SubPageHeaderComponent title={Enums.DOCUMENTS}/>
        <DocumentsComponent/>
      </div>

    )
  }
}

export default DocumentsView;
