import React from 'react';
import BaseComponent from '../common/BaseComponent';

class DosingGuidelinesComponent extends BaseComponent {
  constructor(props) {
     super(props);
     this.state = {
     };
   }

  render(){

    return(

        <div className="page-content">
          <div className="content-title">
            <h4>Amikacin</h4>
          </div>
          <div className="row">
            <div className="col-md-6 top-bottom-margin">
              <ul className="list-group">
                <li className="list-group-item list-group-title">Paternal (IV) Antibiotics</li>
                <li className="list-group-item"><div className="drug-price">$$</div>7.5 mg/kg/dose q8h</li>
                <li className="list-group-item"><div className="drug-price">$$</div>22.5 mg/kg/dose q8h</li>
                <li className="list-group-item"><div className="drug-price">$</div>CF: 7.5 mg/kg/dose q8h</li>
                <li className="list-group-item"><div className="drug-price">$$</div>CF: Day 1: 30 mg/kg/dose q24h</li>
              </ul>
            </div>
            <div className="col-md-6 top-bottom-margin">
              <ul className="list-group">
                <li className="list-group-item list-group-title">Comments & Alerts</li>
                <li className="list-group-item">
                  <p>Renal dose adjustment</p>
                  <p><i className="fa fa-warning mr-2 amber-text" aria-hidden="true"></i><strong>Amikacin is running low, use alternative medication</strong></p>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 top-bottom-margin">
              <ul className="list-group">
                <li className="list-group-item list-group-title">Antimicrobial Nebulization</li>
                <li className="list-group-item"><div className="drug-price">$$</div>250 mg INH q12h</li>
              </ul>
            </div>
          </div>
        </div>

    )
  }
}

export default DosingGuidelinesComponent
