// THIS IS AN UNUSED COMPONENT
import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import SubPageHeaderComponent from '../components/common/SubPageHeaderComponent';
import * as DrugDosageActions from '../actions/drugdosage-actions';
import DosingGuidelineComponent from '../components/DosingGuideline/DosingGuidelineComponent';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Enums from '../enums';

class DosingGuidelineView extends BaseComponent {

  constructor(props){
    super(props);
    this.state = {
    }
  }

  componentDidMount(){
    // THIS IS AN UNUSED COMPONENT
    this.props.drugDosageActions.getlastPublishedDosingDrugById(this.props.match.params.drugId)
  }

  render(){
    return(
      <div>
        <SubPageHeaderComponent title={Enums.DOSING_GUIDELINE}/>
        <div className="my-sm-4"></div>

        <DosingGuidelineComponent drugDosage={this.props.drugDosage}/>
      </div>
    )
  }
}

function mapStateToProps(state){
  return{
    drugDosage: state.drugState.lastPublishedDrugDosing,
    references: state.configRefState.configRef
  }
}

function mapDispatchToProps(dispatch){
  return{
    drugDosageActions: bindActionCreators(DrugDosageActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DosingGuidelineView)
