import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
// import { Link } from 'react-router';
import axios from 'axios';
import { Link } from 'react-router-dom'
import DiagnosisView from './DiagnosisView'
import HomeSearchComponent from '../components/common/HomeSearchComponent';
import { Input } from 'mdbreact'
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as ClientActions from '../actions/client-actions';
import * as Enums from '../enums';


class HomeView extends BaseComponent {

    constructor(props) {
      super(props)
    }

    componentWillMount(){
      this.props.ClientActions.getAllAlerts()
      if(sessionStorage.getItem('preview') == "true" || this.props.location.search.includes("preview=true")){
        sessionStorage.setItem('preview', true)
      }else{
        sessionStorage.setItem('preview', false)
      }
    }

    componentDidMount(){
    }

    trackEvent(sectionName){
      gtag('event', 'click', {
     'event_category': 'Home',
     'event_label': sectionName
     });
      // ga('send', {
      //   hitType: 'event',
      //   eventCategory: sectionName,
      //   eventAction: 'click',
      //   eventLabel: 'Home'
      // });
    }

    populateAlerts(){
      var alertsList = []
      {this.props.alertsList.map((alert, index) => {
        return(
          alertsList.push(alert.alertText)
        )
      })
      }

      if(alertsList.length > 0){
        return(
          <div className="home-alerts-list mt-4 mt-sm-0">
            <div className="alerts-title">
              <i className="fa fa-warning mr-2 amber-text" aria-hidden="true"></i>ALERTS
            </div>
            {alertsList.map((alert, index) => {
              return(
                <div key={index} className="single-alert">{alert}</div>
              )
            })
            }
          </div>
        )
      }
    }

    render(){
      return(
        <div className="home">
          <div className="container-fluid d-flex flex-column h-100 p-0">
            <div className="">
              <img id="bch-logo" src="assets/img/BCH_Logo.png" alt="Antibiogram"/>
              {(sessionStorage.getItem('preview') == "true") &&
                <span id="preview-watermark">PREVIEW</span>
              }
            </div>
            <div className="mt-auto mb-auto d-flex justify-content-center">
              <div className="col-lg-9">
                <div className="row mx-auto logo">
                  <div className="col-12 my-4 my-md-3"><img src="assets/img/svg/AB-Logo.svg" alt="Antibiogram+"/></div>
                </div>
                <div className="row p-md-0">
                  <div className="col-12 my-0 my-sm-4 p-0"><HomeSearchComponent page="Home"/></div>
                </div>
                <div className="row d-flex justify-content-center mt-4 mt-md-0">
                  <div className="p-2">
                    <Link to="/susceptibilityMatrix">
                      <button onClick={() => {this.trackEvent("Antibiotic Susceptibilities")}} type="button" className="btn btn-default p-2">
                        <img src="assets/img/susceptibility-matrix-icon.png" alt="Antibiogram"/>
                        <p className="mb-1 mt-2">{Enums.SUSCEPTIBILITY_MATRIX}</p>
                      </button>
                    </Link>
                  </div>
                  {/* <div className="p-2">
                    <Link to="/diagnoses">
                      <button onClick={() => {this.trackEvent("Antibiotic Recommendations")}} type="button" className="btn btn-default p-2">
                        <img src="assets/img/diagnoses-icon.png" alt="Antibiotic Recommendations"/>
                        <p className="mb-1 mt-2">{Enums.DIAGNOSES}</p>
                      </button>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Link to="/dosingGuidelines">
                      <button onClick={() => {this.trackEvent("Dosing Quick References")}} type="button" className="btn btn-default p-2">
                        <img src="assets/img/dosing-guidelines-icon.png" alt="Dosing Quick References"/>
                        <p className="mb-1 mt-2">{Enums.DOSING_GUIDELINES}</p>
                      </button>
                    </Link>
                  </div>
                  <div className="p-2">
                    <a href="http://web2.tch.harvard.edu/asp/index.html" target="_blank">
                      <button onClick={() => {this.trackEvent("Resource Materials")}} type="button" className="btn btn-default p-2">
                        <img src="assets/img/compare-drugs-icon.png" alt="Additional Resources"/>
                        <p className="mb-1 mt-2">{Enums.DOCUMENTS}</p>
                      </button>
                    </a>
                  </div>*/}
                </div> 

                {this.populateAlerts()}

              </div>
            </div>
          </div>
            <div className="p-4 disclaimer">This tool is for educational purposes only. Antibiotic susceptibility data are intended for use solely at Boston Children’s and do not represent susceptibility patterns outside of Boston Children’s. Expected susceptibilities for bacteria not tested at Boston Children’s reflect expert opinion and national surveys, and susceptibility results for an individual isolate may differ from the patterns displayed here. Evaluation and treatment decisions are the responsibility of the treating clinician and should always be tailored to individual circumstances and current microbiology data. Infectious Diseases consultation is recommended for serious or unusual infections.</div>
        </div>
      )
    }
}

function mapStateToProps(state){
  return{
    alertsList: state.clientState.alertsList
  }
}

function mapDispatchToProps(dispatch){
  return{
    ClientActions: bindActionCreators(ClientActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
