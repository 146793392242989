import React from 'react'
import { Route, IndexRoute, IndexRedirect,NotFoundRoute } from 'react-router';
import App from './views/App'
import HomeView from './views/Home';
import DiagnosesView from './views/DiagnosesView'

export const Routes = () => {

  return (
    <Route path="/" component={App}>
      <IndexRoute component={HomeView}/>
      <Route path="/diagnoses" component={DiagnosesView}/>
    </Route>
  )
}

export default Routes
