import * as ActionTypes from './action-types';
import * as drugApi from '../api/drug-api';

export function getDrugList() {
  return function(dispatch) {
      return drugApi.getDrugsList()
      .then((drugList) => {
        dispatch({type: ActionTypes.GET_DRUGLIST_SUCCESS, drugList});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function getDrugById(drugId) {
  return function(dispatch) {
      return drugApi.getDrugById(drugId)
      .then((drug) => {
        dispatch({type: ActionTypes.GET_DRUGBYID_SUCCESS, drug});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function loadDrugInfo(drug) {
  return function(dispatch){
    dispatch({type: ActionTypes.GET_DRUG_SUCCESS, drug});
  }
}
