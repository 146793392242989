import React from 'react';
import { connect } from 'react-redux';
import TDMModal from './TDMModal'
import RenalDosingModal from './RenalDosingModal'
import BaseComponent from '../common/BaseComponent';
import { Tooltip, Button, Fa } from 'mdbreact';
import { Link } from 'react-router-dom'

class DosingGuidelineComponent extends BaseComponent {
  constructor(props) {
     super(props);
     this.state = {
       showLegend:false
     };
   }

   toggleLegend(){
     if(this.state.showLegend){
       this.setState({showLegend:false})
     }else{
       this.setState({showLegend:true})
       gtag('event', 'click', {
          'event_category': 'Dosing Quick References',
          'event_label': "Legend opened"
        })
     }
   }

   trackViewInMatrix(){
     gtag('event', 'click', {
       'event_category': 'Dosing Quick References',
       'event_label': "View in Matrix"
     })
   }

   populateReference(referenceType, code){
     if(Number.isInteger(parseInt(code))){
       return code;
     }else{
       var referenceList = this.props.references
       // var referenceObject = _(referenceList).find(['name', 'referenceType']).find('list.code', 'code')
       var referenceGroup = _.find(referenceList, { 'name': referenceType});
       var referenceObject = _.find(referenceGroup.list, { 'code': code });
       if(referenceObject === undefined){
         var otherObject = {}
         otherObject.typeName = code
         otherObject.type = "other"
         referenceObject = otherObject
       }
       return referenceObject
     }
   }

  ageRangeString(ageRange){
    var ageRangeStr = "";

    // If age range contains from and to...
    if(ageRange.from && ageRange.to){
      if(ageRange.from.age == 1){
        ageRange.from.unit = ageRange.from.unit.substring(0, ageRange.from.unit.length - 1);
      }
      if(ageRange.to.age == 1){
        ageRange.to.unit = ageRange.to.unit.substring(0, ageRange.to.unit.length - 1);
      }
      var from = ageRange.from.inclusion + " " + ageRange.from.age + " " + ageRange.from.unit.toLowerCase()
      var to = ageRange.to.inclusion + " " + ageRange.to.age + " " + ageRange.to.unit.toLowerCase()
      ageRangeStr = from + " to " + to + ": "
    // If age range contains only from
    }else if (ageRange.from) {
      if(ageRange.from.age == 1){
        ageRange.from.unit = ageRange.from.unit.substring(0, ageRange.from.unit.length - 1);
      }
      var from = ageRange.from.inclusion + " " + ageRange.from.age + " " + ageRange.from.unit.toLowerCase()
      ageRangeStr = from + ": "
    // If age range contains only to
    }else{
      if(ageRange.to.age == 1){
        ageRange.to.unit = ageRange.to.unit.substring(0, ageRange.to.unit.length - 1);
      }
      var to = ageRange.to.inclusion + " " + ageRange.to.age + " " + ageRange.to.unit.toLowerCase()
      ageRangeStr = to + ": "
    }

    return ageRangeStr;
  }

  dosageString(dosing){
    var dosingString = "";
    var frequency = "";
    var duration = "";
    var prefix = "";
    var maxDose = "";
    var ageRange = "";

    if(dosing.prefix){
      prefix = dosing.prefix + " "
    }
    var dosage = dosing.dosage.dose + ' ' + this.populateReference('Dose Unit', dosing.dosage.unit).typeName;
    if(dosing && dosing.ageRange){
      ageRange = this.ageRangeString(dosing.ageRange)
    }
    if(dosing.frequency){
      var frequencyObject = this.populateReference('Frequency Units', dosing.frequency.unit);
      if(frequencyObject && frequencyObject.typeName) {
        frequency = frequencyObject.typeName;
      }else{
        frequency = frequencyObject;
      }
      if(frequency && frequency.length>0){
        frequency = ' ' + frequency;
      }
    }
    if(dosing.duration){
      var durationObject = this.populateReference('Duration Unit', dosing.duration.unit);
      if(durationObject && durationObject.typeName) {
        duration = dosing.duration.time + ' ' + durationObject.typeName;
      }else{
        duration = dosing.duration.time + ' ' + durationObject;
      }
      if(duration && duration.length>0){
        duration = ' x ' + duration;
      }
    }
    if(dosing.maxDose){
      maxDose = " (" + dosing.maxDose.dose +  " " + this.populateReference('Max Dose Unit', dosing.maxDose.unit).typeName + ")";
    }
    dosingString = prefix + ageRange + dosage + frequency + duration + maxDose ;
    // console.log(dosingString)
    return dosingString;
  }

  renderComments(dosingItems){
    if(dosingItems){
      return dosingItems.map((dosingItem, index) => {
        return(
          <p key={index}>{dosingItem.notes}</p>
        )
      })
    }
  }

  renderTag(referenceCode){
    var referenceObject = this.populateReference("Special Considerations", referenceCode)
    if(referenceObject && referenceObject.type && referenceObject.type == "tag"){
      if(referenceObject.abbr == "Therapeutic Drug Monitoring"){
        return <TDMModal/>
      }else{
        return <Tooltip placement="bottom" componentClass="mt-2 mt-md-0 special-cons-btn cursor-default" tag="div" tooltipContent={referenceObject.typeName}>{referenceObject.abbr}</Tooltip>
      }
    }
  }

  renderSpecialConsiderations(referenceCode){
    var referenceObject = this.populateReference("Special Considerations", referenceCode)
    if(referenceObject && referenceObject.type && referenceObject.type == "comment" || referenceObject.type == "Renal Dosing"){
      if(referenceObject.type == "comment"){
        return <p>{referenceObject.typeName}</p>
      }
      if(referenceObject.type == "Renal Dosing"){
        return <p>{referenceObject.typeName}<br/><RenalDosingModal/></p>
      }
    }
  }


  render(){
    const dosage = this.props.drugDosage
    if(this.props.renderedFromList != true && dosage.drug){
      gtag('event', 'view', {
        'event_category': "Dosing Quick Reference View",
        'event_label': dosage.drug.name
      })
    }
    return(
      <div className="page-content">

        <div className="row content-title">
          {dosage.drug &&
            <div className="d-sm-flex justify-content-start align-items-center col-12">
              {!this.props.viewOnly && this.props.showMatrixButton &&
                <Link to={'/susceptibilityMatrix/drug/' + dosage.drug._id}><Button size="sm" floating className="d-inline-block d-sm-none no-shadow antibiogram-gradient view-in-matrix-btn p-0"><Fa icon="th" /></Button></Link>
              }
              <div className="d-sm-flex justify-content-center align-items-center">
                <Link to={'/dosingGuideline/'+dosage.drug._id} className="mr-sm-4"><h4>{dosage.drug.name}</h4></Link>
                {dosage.drug.specialConsiderations.map((specialConsideration, index) => {
                  return(
                    <span key={index} className="mr-sm-2">
                      {this.renderTag(specialConsideration)}
                    </span>
                  )
                })}
              </div>
              {!this.props.viewOnly && this.props.showMatrixButton &&
                <Link to={'/susceptibilityMatrix/drug/' + dosage.drug._id} className="d-none d-sm-inline-block ml-auto text-white"><div className="mx-xs-0 btn btn-default btn-sm btn-rounded no-shadow antibiogram-gradient" onClick={()=>{this.trackViewInMatrix()}}>View in Antibiogram<i className="fa fa-th ml-2" aria-hidden="true"></i></div></Link>
              }
            </div>
          }
        </div>
        <div className="row">
          <div className="col-md-6">

            {dosage.dosingItems && dosage.dosingItems.map((dosingItem, index) => {
              return(
                <div key={index} className="row">
                  <div className="col-12 top-margin">
                    <ul className="list-group">
                      <li className="list-group-item list-group-title d-flex justify-content-between align-items-center">
                        {this.populateReference("Administration Route", dosingItem.routeOfAdministration).typeName}
                        {/* <span onClick={()=>{this.toggleLegend()}} className="float-right cost-legend-btn">
                          <Fa icon={this.state.showLegend ? "caret-down" : "info"} className="dollar-icon"/>
                        </span> */}
                      </li>
                      {/* <li className={"list-group-item cost-legend " + (!this.state.showLegend && "h-0 p-0" )}>
                        <div className="row">
                          <div className="col-6 col-lg-3 mb-2 mb-lg-0 px-1">
                            <div className="cost-legend-icon">$</div><div className="cost-legend-value">$1-$10</div>
                          </div>
                          <div className="col-6 col-lg-3 mb-2 mb-lg-0 px-1">
                            <div className="cost-legend-icon">$$</div><div className="cost-legend-value">$11-$25</div>
                          </div>
                          <div className="col-6 col-lg-3 px-1">
                            <div className="cost-legend-icon">$$$</div><div className="cost-legend-value">$26-$50</div>
                          </div>
                          <div className="col-6 col-lg-3 px-1">
                            <div className="cost-legend-icon">$$$$</div><div className="cost-legend-value">&#8805;$51</div>
                          </div>
                        </div>
                      </li> */}
                      {dosingItem.dosings.map((dosing, index) => {
                        return(
                          <li key={index} className="list-group-item "><div className="drug-price">{this.populateReference("Drug Cost Level", dosing.costLevel).typeName}</div>{this.dosageString(dosing)}</li>
                        )
                       })}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="col-md-6 top-bottom-margin">
            <ul className="list-group">
              <li className="list-group-item list-group-title">Comments & Alerts</li>
              <li className="list-group-item">
                {dosage.drug && dosage.drug.specialConsiderations.map((specialConsideration, index) => {
                  return(
                    <span key={index}>
                      {this.renderSpecialConsiderations(specialConsideration)}
                    </span>
                  )
                })}
                {this.renderComments(dosage.dosingItems)}
                {dosage.drug && dosage.drug.alerts && dosage.drug.alerts.map((alert, index) => {
                  return(
                    <p key={index}><i className="fa fa-warning mr-2 amber-text" aria-hidden="true"></i>{alert.alertText}</p>
                  )
                })}
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return{
    references: state.configRefState.configRef
  }
}

export default connect(mapStateToProps)(DosingGuidelineComponent)


// {this.props.dosingsList && this.props.dosingsList.map((dosing, index) => {
//     return(
//       <div key={dosing.drug.name}>
//         <h5>{dosing.drug.name}</h5>
//         <ul>
//           {dosing.dosingItems.map((dosingItem, index) => {
//             return(
//               <li key={index}>
//                 <h6>{dosingItem.routeOfAdministration}</h6>
//                 <ul>
//                   {dosingItem.dosings.map((dosing, index) => {
//                     return(
//                       <li key={index}>{dosing.dosage.dose+dosing.dosage.unit}</li>
//                     )
//                   })}
//                 </ul>
//               </li>
//             )
//           })}
//         </ul>
//       </div>
//     )
// })}
