import axios from 'axios';

function preview(){
  if(sessionStorage.getItem('preview') == "true"){
    return "?preview=true"
  }else{
    return ""
  }
}

export function getDiagnoseList() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'diagnoses'+preview())
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function getDiagnosesByGroupName() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'client/getDiagnosesByGroupName'+preview())
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function getDiagnoseById(diagnoseId) {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'diagnosis/'+ diagnoseId+preview())
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}

export function getCurrentDiagnosisCard(diagnoseId) {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'client/getDiagnosisCardByDiagnosisId/' + diagnoseId+preview())
    .then(response => {
      return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}
