import React from 'react';
import BaseComponent from './BaseComponent';
import SearchComponent from './HomeSearchComponent';
import MenuButtonComponent from './SubPageMenuButtonComponent';
import { Button, Collapse, ListGroup, ListGroupItem, Fa} from 'mdbreact';
import { Link, NavLink, withRouter } from 'react-router-dom';
// import { Switch, Route, NavLink } from 'react-router-dom'

class SubPageHeaderComponent extends BaseComponent {
  constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);

      this.state = {
        collapse: false,
      };
    }

    toggle() {
      this.setState({ collapse: !this.state.collapse });
    }

  render() {
    return (
      <span id="subpageHeader">
        <div className="clearfix d-none d-md-block">
          <div className="float-left">
            <h2>{this.props.title && this.props.title}</h2>
            <div onClick={this.props.history.goBack} className="pointer back-btn"><Fa icon="angle-left" className="mr-1"/>Back</div>
          </div>
          <i className="fa fa-lg fa-navicon float-right d-block d-md-none" aria-hidden="true"></i>
          <div className="float-md-right subpage-search">
            <SearchComponent/>
          </div>
        </div>
        <div className="d-block d-md-none gradient">
          <div className="d-flex justify-content-between align-items-center py-4 px-3">
            <div onClick={this.props.history.goBack} className="float-left text-white pointer">
              <Fa icon="angle-left" size="lg" className="mr-1"/>
            </div>
            <div>
              <h5 className="mb-0 mx-3 text-white subpage-title text-center">{this.props.title && this.props.title}</h5>
            </div>
            <div onClick={this.toggle}>
              <i className="fa fa-lg fa-navicon float-right text-white pointer" aria-hidden="true"></i>
            </div>
          </div>
          <Collapse isOpen={this.state.collapse}>
            <ListGroup onClick={this.toggle}>
              <Link to={'/'}><ListGroupItem><Fa icon="home" size="sm" className="mr-2"/>Home</ListGroupItem></Link>
              <NavLink activeClassName="activeHeaderMenu" to={'/susceptibilityMatrix'}><ListGroupItem>Susceptibility Matrix</ListGroupItem></NavLink>
              <NavLink activeClassName="activeHeaderMenu" to={'/diagnoses'}><ListGroupItem>Diagnoses</ListGroupItem></NavLink>
              <NavLink activeClassName="activeHeaderMenu" to={'/dosingGuidelines'}><ListGroupItem>Dosing Guidelines</ListGroupItem></NavLink>
              <NavLink activeClassName="activeHeaderMenu" to={'/documents'}><ListGroupItem>Documents</ListGroupItem></NavLink>
            </ListGroup>
          </Collapse>
          <div className="subpage-search">
            <SearchComponent className="w-100"/>
          </div>
        </div>
        <div className="gradient-line mt-md-3 d-none d-md-block"></div>
      </span>
    );
  }
}

export default withRouter(SubPageHeaderComponent);
