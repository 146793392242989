import { combineReducers } from 'redux';
import diagnosisReducer from './diagnosis-reducer';
import susceptibilityMatrixReducer from './susceptibilityMatrix-reducer';
import configRefReducer from './configRef-reducer';
import drugReducer from './drug-reducer';
import clientReducer from './client-reducer';
// import * as types from '../actions/action-types';
// import bacteriaReducer from './bacteria-reducer';
// import FormReducer from './form-reducer';

const rootReducer = combineReducers({
  diagnoseState: diagnosisReducer,
  susceptibilityMatrixState:susceptibilityMatrixReducer,
  configRefState:configRefReducer,
  clientState:clientReducer,
  drugState:drugReducer

  // bacteriaState: bacteriaReducer,
  // form: FormReducer

})

export default rootReducer
