import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import SubPageHeaderComponent from '../components/common/SubPageHeaderComponent';
import DosingGuidelineComponent from '../components/DosingGuideline/DosingGuidelineComponent';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as DosingActions from '../actions/drugdosage-actions';

class CompareDosingGuidelinesView extends BaseComponent {

  constructor(props){
    super(props)
    this.state = {
    }
  }

  componentDidMount(){
    // this.props.dosingActions.getFilteredDosings()
  }

  render(){
    return(
      <div>
        <h1>Dosings: {this.props.dosingsList && this.props.dosingsList.length}</h1>
        {/* <SubPageHeaderComponent title="Dosing Guidelines"/> */}
          <div className="page-content container">
            {this.props.dosingsList && this.props.dosingsList.length > 0 && this.props.dosingsList.map((dosing, index) => {
              return(
                <div key={index} className="single-dosing">
                  <DosingGuidelineComponent  drugDosage={dosing} viewOnly={true}/>
                </div>
              )
            })}
          </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return{
    dosingsList: state.drugState.filteredDrugDosing,
    references: state.configRefState.configRef
  }
}


export default connect(mapStateToProps)(CompareDosingGuidelinesView)
