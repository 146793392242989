import * as ActionTypes from './action-types';
import * as SMApi from '../api/susceptibiltyMatrix-api';
import _ from 'lodash';

export function getCurrentPublishedSM() {
  return function(dispatch) {
    return SMApi.getCurrentPublishedSM()
    .then((currentPublishedSM) => {
      var publishedSM = _.chain(currentPublishedSM.lineItems).groupBy(function(o){return o.bacteria.category}).map(function(array, i) {
        return {
          bacteriaGram: i,
          cultureSources: _.chain(array).groupBy('cultureSource').map(function(b,d){
            return{
              cultureSource:d,
              drugs: _(b).groupBy(function(m){return m.drug.name}).map(function(e,f){
                return{
                  drug:f,
                  lineItemsByDrug:e
                }
              }).value()
            }
          }).value()
        }
      }).value();
      dispatch({type: ActionTypes.GET_CURRENTSM_SUCCESS, publishedSM});
    })
    .catch(error => {
      throw(error);
    })
  }
}

export function getLatestPublishedSM() {
  return function(dispatch) {
      return SMApi.getLatestPublishedSM()
      .then((latestPublishedSM) => {
        dispatch({type: ActionTypes.GET_LATESTPUBLISHEDSM_SUCCESS, latestPublishedSM});
      })
      .catch(error => {
        throw(error);
      })
  }
}
