import "babel-polyfill";
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router, browserHistory, hashHistory } from 'react-router'
import configureStore from './configureStore'
import {Routes} from './routes'
import $ from 'jquery'
import '../assets/scss/_custom.scss'
import '../assets/scss/_homepage.scss'
// import '../assets/scss/mdb.scss'
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'mdbreact/dist/css/mdb.css'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import ReactDOM from 'react-dom';
import App from './views/App.js'

const store = configureStore();


ReactDOM.render((
  <Provider store={store}>
    <HashRouter>
      <App/>
    </HashRouter>
  </Provider>
), document.getElementById('app'))

// render(<Routing />, document.getElementById('app'))
