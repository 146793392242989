import React from 'react';
import BaseComponent from '../common/BaseComponent';
import SubPageHeaderComponent from '../common/SubPageHeaderComponent';
import * as SMActions from '../../actions/susceptibilityMatrix-actions';
import * as DrugDosageActions from '../../actions/drugdosage-actions';
import {bindActionCreators} from 'redux';
import SMDisplayComponent from './SMDisplayComponent';
import SMLegendComponent from './SMLegendComponent';
import CompareDosingGuidelinesModal from './CompareDosingGuidelinesModal';
import Moment from 'react-moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Collapse, ListGroup, ListGroupItem, Badge, Fa } from 'mdbreact';

class SusceptibilityMatrixComponent extends BaseComponent {
  constructor(props) {
     super(props);
     this.state = {
       uncheckedBugIds:[],
       uncheckedDrugIds:[],
       allBugIds:[],
       allDrugIds:[],
       collapse: false,
       initialStateSet: false,
       showNotes:false
     };
   }

   componentDidMount(){
     this.setInitialState()
   }

   componentWillMount(){
   }

   showNotes(){
     this.setState({showNotes:true})
   }

   componentDidUpdate(nextProps, nextState){
     if(nextProps.path != this.props.path){
       this.setInitialState()
     }
   }

   listBugNotes(bugs){
     var bugs = [];
     {this.props.SMBugs.map((bug, index) => {
       if(bug.notes){
         bugs.push(bug)
       }
     })}

     if(bugs.length > 0){
       return(
         <span>
           <ListGroupItem>Notes</ListGroupItem>
              <ListGroupItem key="static_note">For bacteria not tested at BCH, expected sensitivity (S) or resistance (R) reflect expert opinion and national surveys. Susceptibility results for an individual isolate may differ from these patterns and should be used to guide treatment when available. (S) denotes expected susceptibility of 80-100%, (S/R) denotes variable susceptibility, typically 60-80%, and (R) denotes susceptibility &lt; 60% or antibiotics that are not recommended for the specified bacteria or expected site of infection.</ListGroupItem>
           {bugs.map((bug, index) => {
             return(
               <ListGroupItem key={index}><Badge className="notes-badge" pill>{bug.name}</Badge><br/>{bug.notes}</ListGroupItem>
             )
           })}
         </span>

       )
     }

   }

   setInitialState = () => {
     var allBugIds = []
     var allDrugIds = []

     for(var i=0;i<this.props.SMDrugs.length;i++){
       allDrugIds.push(this.props.SMDrugs[i]._id)
     }
     for(var i=0;i<this.props.SMBugs.length;i++){
       allBugIds.push(this.props.SMBugs[i]._id)
     }

     this.setState({allBugIds:allBugIds, allDrugIds:allDrugIds})

     if(this.props.drugId){
       var drugId = this.props.drugId
       var uncheckedDrugIds = []
       for(var i=0;i<this.props.SMDrugs.length;i++){
         if(this.props.SMDrugs[i]._id != drugId){
           uncheckedDrugIds.push(this.props.SMDrugs[i]._id)
         }
       }
       this.setState({uncheckedDrugIds:uncheckedDrugIds})
     }
     if(this.props.bugId){
       var bugId = this.props.bugId
       var uncheckedBugIds = []
       for(var i=0;i<this.props.SMBugs.length;i++){
         if(this.props.SMBugs[i]._id != bugId){
           uncheckedBugIds.push(this.props.SMBugs[i]._id)
         }
       }
       this.setState({uncheckedBugIds:uncheckedBugIds})
     }
     if(!this.props.drugId && !this.props.bugId){
       this.setState({uncheckedDrugIds:[], uncheckedBugIds:[]})
     }
   }

   toggle = () => {
     this.setState({ collapse: !this.state.collapse });
     var action = ""
     if(!this.state.collapse){
       action = "open"
     }else{
       action = "close"
     }

     gtag('event', 'click', {
        'event_category': 'SM Filter',
        'event_label': action
    })
   }

   selectAll = (type) => {
     var arr = [];
     if(type == "bug"){
       this.setState({uncheckedBugIds:arr})
     }
     if(type == "drug"){
       this.setState({uncheckedDrugIds:arr})
     }

     gtag('event', 'click', {
        'event_category': 'SM Filter',
        'event_label': "select all " + type
      })
   }

   deselectAll = (type) => {
     if(type == "bug"){
       this.setState({uncheckedBugIds:this.state.allBugIds})
     }
     if(type == "drug"){
       this.setState({uncheckedDrugIds:this.state.allDrugIds})
     }

     gtag('event', 'click', {
        'event_category': 'SM Filter',
        'event_label': "deselect all " + type
      })
   }

   checkBug = (bug) => {
     var newUncheckedBugIds = _.clone(this.state.uncheckedBugIds)
     var uncheckedBugIdsClone = _.clone(this.state.uncheckedBugIds)

     // If the bug was already unchecked -- remove from list
     if(_.includes(uncheckedBugIdsClone, bug._id)){
       newUncheckedBugIds = _.remove(uncheckedBugIdsClone, function(bugId){
         return bugId != bug._id
       })
       gtag('event', 'bug checked', {
          'event_category': 'SM Filter',
          'event_label': bug.name
        })
     }else{
       newUncheckedBugIds.push(bug._id)
       gtag('event', 'but unchecked', {
          'event_category': 'SM Filter',
          'event_label': bug.name
        })
     }
     this.setState({uncheckedBugIds: newUncheckedBugIds});
   }

   checkDrug = (drug) => {
     var newUncheckedDrugIds = _.clone(this.state.uncheckedDrugIds)
     var uncheckedDrugIdsClone = _.clone(this.state.uncheckedDrugIds)

     // If the bug was already unchecked -- remove from list
     if(_.includes(uncheckedDrugIdsClone, drug._id)){
       newUncheckedDrugIds = _.remove(uncheckedDrugIdsClone, function(drugId){
         return drugId != drug._id
       })
       gtag('event', 'drug checked', {
          'event_category': 'SM Filter',
          'event_label': drug.name
        })
     }else{
       newUncheckedDrugIds.push(drug._id)
       gtag('event', 'drug unchecked', {
          'event_category': 'SM Filter',
          'event_label': drug.name
        })
     }
     this.setState({uncheckedDrugIds: newUncheckedDrugIds});
   }

   filterLineItems = (sm) => {
     var matrix = Object.assign(sm)
     var lineItems = []
       for(var i=0;i<sm.lineItems.length;i++){
         if(!this.state.uncheckedBugIds.includes(matrix.lineItems[i].bacteria._id) && !this.state.uncheckedDrugIds.includes(matrix.lineItems[i].drug._id)){
           lineItems.push(matrix.lineItems[i])
         }
       }
       matrix.lineItems = lineItems
     return matrix
   }

   viewDosingGuidelines = () => {
    //  var newTab = $window.open('', '_blank');
    // promise
    // .then(function () {
    //     var url = '...';
    //     newTab.location.href = url;
    // });
     var newTab = window.open('','CompareDosingGuidelines','height=800,width=1250');
     var checkedDrugIds = _.difference(this.state.allDrugIds, this.state.uncheckedDrugIds);
     this.props.DrugDosageActions.getFilteredDosings(checkedDrugIds).then((result) => {
       var url = '/compareDosingGuidelines/';
       newTab.location.href = url;
       // this.openDosingGuidelinesWindow()
     }).catch(error => {
       throw(error)
     })
     gtag('event', 'click', {
        'event_category': 'SM Filter',
        'event_label': "view dosing guidelines"
      })
   }

   openDosingGuidelinesWindow = () => {
     var url = '/compareDosingGuidelines/'
     var win = window.open(url,'CompareDosingGuidelines','height=800,width=1250')
     win.location.reload()
   }


  render(){
    const matrix = _.clone(this.props.SM)
    return(
        <div className="page-content">
          <div className="row">
            <div className="col-12 bottom-margin">
              <ul className="list-group">
                <li className="list-group-item list-group-title">
                  Common Pathogens Susceptibility Matrix
                  <p className="last-updated-text">Last Updated: <Moment format="MM/DD/YYYY">{this.props.SM.updatedDt}</Moment></p>
                </li>
                <li className="list-group-item">
                  <div className="clearfix mb-2 mb-md-4">
                    <div className="float-left">
                      <SMLegendComponent/>
                    </div>
                    <button onClick={this.toggle} className={"float-right teal lighten-2 btn gradient btn-rounded btn-md m-0 sm-filter-btn " + (this.state.collapse && "sm-filter-btn-close")}>
                      <i className={"fa fa-lg " + (this.state.collapse ? "fa-chevron-down " : "fa-filter ")+ ((this.state.uncheckedBugIds.length > 0 || this.state.uncheckedDrugIds.length > 0) && "amber-text darken-2")} aria-hidden="true"></i>
                      { !this.state.collapse && <span className="ml-2 d-inline-block">Bugs/Drugs Filter</span> }
                    </button>

                    {/* <button onClick={this.toggle} className={"float-right teal lighten-2 btn gradient btn-rounded btn-md sm-filter-btn " + (this.state.collapse && "sm-filter-btn-close")}>
                      <i className={"fa fa-lg " + (this.state.collapse ? "fa-close " : "fa-filter ")+ ((this.state.uncheckedBugIds.length > 0 || this.state.uncheckedDrugIds.length > 0) && "amber-text darken-2")} aria-hidden="true"></i>
                      { !this.state.collapse && <span className="ml-2 d-none d-md-inline-block">Bugs/Drugs Filter</span> }
                    </button> */}

                  </div>
                  <Collapse isOpen={this.state.collapse} className="bugs-drugs-filter-list">
                    <hr/>
                    <div className="">
                      <div className="clearfix mb-2">
                        <h4 className="float-left">Bugs</h4>
                        <div className="float-right">
                          {this.state.uncheckedBugIds.length > 0 &&
                            <span className="select-deselect-all" onClick={() => this.selectAll("bug")}>
                              select all bugs
                              {/* <i className="fa fa-check-square ml-1" aria-hidden="true"></i> */}
                            </span>
                          }
                          {this.state.uncheckedBugIds.length != this.state.allBugIds.length &&
                            <span className="ml-4 select-deselect-all" onClick={() => this.deselectAll("bug")}>
                              deselect all bugs
                              {/* <i className="fa fa-square-o ml-1" aria-hidden="true"></i> */}
                            </span>
                          }
                        </div>
                      </div>
                      <div className="d-flex flex-wrap form-check">
                        {this.props.SMBugs.map((bug, index) => {
                          return(
                            <div key={index} className="col-md-6 col-lg-4 mb-2 bug-check">
                              <input type="checkbox" className="filled-in form-check-input" id={'bug' + index} checked={!_.includes(this.state.uncheckedBugIds, bug._id)} onChange={(e) => this.checkBug(bug)}/>
                              <label className="form-check-label" htmlFor={'bug' + index}>{bug.name}</label>
                            </div>
                          )
                        })
                        }
                      </div>
                      <hr/>
                      <div className="mb-2 d-flex align-items-center justify-content-between">
                        <div className="d-md-flex align-items-center">
                          <h4 className="d-block-inline">Drugs</h4>
                          <span className="">
                            <CompareDosingGuidelinesModal allDrugIds={this.state.allDrugIds} uncheckedDrugIds={this.state.uncheckedDrugIds}/>
                          </span>
                        </div>
                        {/* <div className="ellipsis-menu d-block d-md-none align-right">
                          <Fa size="lg" icon="ellipsis-v"/>
                          <ListGroup className="ellipsis-menu-dropdown">
                            <ListGroupItem>Cras justo odio</ListGroupItem>
                            <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
                            <ListGroupItem>Morbi leo risus</ListGroupItem>
                          </ListGroup>
                        </div> */}
                        <div className="">
                          {this.state.uncheckedDrugIds.length > 0 &&
                            <span className="select-deselect-all" onClick={() => this.selectAll("drug")}>
                              select all drugs
                              {/* <i className="fa fa-check-square ml-1" aria-hidden="true"></i> */}
                            </span>
                          }
                          {this.state.uncheckedDrugIds.length != this.state.allDrugIds.length &&
                            <span className="ml-4 select-deselect-all" onClick={() => this.deselectAll("drug")}>
                              deselect all drugs
                              {/* <i className="fa fa-square-o ml-1" aria-hidden="true"></i> */}
                            </span>
                          }
                        </div>
                      </div>
                      <div className="d-flex flex-wrap form-check">
                        {this.props.SMDrugs.map((drug, index) => {
                          return(
                            <div key={index} className="col-md-6 col-lg-4 mb-2 drug-check">
                              <input type="checkbox" className="filled-in form-check-input" id={'drug' + index} checked={!(_.includes(this.state.uncheckedDrugIds, drug._id))} onChange={(e) => this.checkDrug(drug)}/>
                              <label className="form-check-label" htmlFor={'drug' + index}>{drug.name}</label>
                            </div>
                          )
                        })
                        }
                      </div>
                    </div>
                  </Collapse>
                  <div className="matrix-section mt-md-2 mt-md-4">
                    <SMDisplayComponent SusMa={this.filterLineItems(matrix)} HeaderRotate={(this.props.SMDrugs.length - this.state.uncheckedDrugIds.length) <= 3 ? false : true}/>
                    <ListGroup className={"SM-notes mt-3"}>
                      {this.listBugNotes(this.props.SMBugs)}
                    </ListGroup>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
    )
  }
}

function mapStateToProps(state){
  return{
    SM: state.susceptibilityMatrixState.SM,
    SMDrugs: state.susceptibilityMatrixState.SMDrugs,
    SMBugs: state.susceptibilityMatrixState.SMBugs,
    dosingsList: state.drugState.filteredDrugDosing
  }
}

function mapDispatchToProps(dispatch){
  return{
    SMActions: bindActionCreators(SMActions, dispatch),
    DrugDosageActions: bindActionCreators(DrugDosageActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SusceptibilityMatrixComponent);
