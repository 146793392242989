import * as types from '../actions/action-types';
import initialState from './initialState';

const configRefReducer = function(state = initialState, action) {

  switch(action.type) {
      case types.GET_CONFIGREFERENCE_SUCCESS:
        return {...state, configRef:action.configRef};  //whatever our current state is, add on "configReference"
  }
  return state;
}

export default configRefReducer
