import React from 'react';
import BaseComponent from './BaseComponent';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import * as Enums from '../../enums';
// import * as DosingActions from '../actions/drugdosage-actions';

class SideNavComponent extends BaseComponent {

  constructor(props){
    super(props)
    this.state = {
    }
  }

  componentDidMount(){
  }

  clickEvent(page){
    gtag('event', 'click', {
       'event_category': 'Side Navigation',
       'event_label': page
     })
  }

  render(){
    return(
      <div className="container-fluid" id="sidenav">
        <Link to={'/'} onClick={()=>{this.clickEvent("Home")}}><img src="assets/img/svg/AB-Logo.svg" alt="Antibiogram+"/></Link>
        <hr/>
        <NavLink activeClassName="active" to={'/susceptibilityMatrix'} onClick={()=>{this.clickEvent("Antibiotic Susceptibilities")}}>
          <button type="button" className="btn btn-transparent">{Enums.SUSCEPTIBILITY_MATRIX}</button>
        </NavLink>
        {/* <NavLink activeClassName="active" to={'/diagnoses'} onClick={()=>{this.clickEvent("Antibiotic Recommendations")}}>
          <button type="button" className="btn btn-transparent">{Enums.DIAGNOSES}</button>
        </NavLink>
        <NavLink activeClassName="active" to={'/dosingGuidelines'} onClick={()=>{this.clickEvent("Dosing Quick References")}}>
          <button type="button" className="btn btn-transparent">{Enums.DOSING_GUIDELINES}</button>
        </NavLink>
        <a href="http://web2.tch.harvard.edu/asp/index.html" target="_blank" onClick={()=>{this.clickEvent("Resource Materials")}}>
          <button type="button" className="btn btn-transparent">{Enums.DOCUMENTS}</button>
        </a>
        <NavLink activeClassName="active" to={'/contact'} onClick={()=>{this.clickEvent("Feedback")}}>
          <button type="button" className="btn btn-transparent">{Enums.CONTACT}</button>
        </NavLink> */}
      </div>
    )
  }
}

function mapStateToProps(state){
  return{
    // dosingsList: state.drugState.drugsWithDosingList
  }
}

function mapDispatchToProps(dispatch){
  return{
    // dosingActions: bindActionCreators(DosingActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNavComponent))
