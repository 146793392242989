import axios from 'axios';
import * as ActionTypes from '../actions/action-types';
import configureStore from '../configureStore'
const store = configureStore();

export function getConfigRefList() {
  return axios.get(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'references')
    .then(response => {
        //storage only supports strings. Use JSON.stringify() and JSON.parse()
        store.dispatch({type: ActionTypes.GET_CONFIGREFERENCE_SUCCESS, configRef:response.data})

        //To retain Redux state through page refreshes, you need to persist the app state by storing it in localStorage and retrieve it on
        //page load. Try to dispatch an action in the componentDidMount of your App component, which retrieves the data from the sessionStorage
        sessionStorage.setItem('configRef', JSON.stringify(response.data));
        // return response.data;
    }).catch(function (error){
      console.log(error);
      return error.response.data;
    });
}


export function getConfigRefListFromSessionStorage() {
    store.dispatch({type: ActionTypes.GET_CONFIGREFERENCE_SUCCESS, configRef:JSON.parse(sessionStorage.getItem('configRef'))}) //storage only supports strings. Use JSON.stringify() and JSON.parse()
}
