import React from 'react';
import { Collapse, Card, CollapseHeader, CardBody, ListGroup, ListGroupItem } from 'mdbreact';
import SubPageHeaderComponent from '../components/common/SubPageHeaderComponent';
import BaseComponent from '../components/common/BaseComponent';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as DiagnosisActions from '../actions/diagnosis-actions';
import { Switch, Route, NavLink } from 'react-router-dom'
import DiagnosisView from './DiagnosisView'
import { Link } from 'react-router-dom'
import _ from 'lodash';
import * as Enums from '../enums';

class DiagnosesView extends BaseComponent {

    constructor(props) {
      super(props)
      this.onClick = this.onClick.bind(this);
      this.toggle = this.toggle.bind(this);
      this.populateReference = this.populateReference.bind(this)
      this.state = {
        collapse: false,
        accordion: false
      };
    }

    onClick(event, index) {
      let state = '';
      if(this.state.accordion !== index) {
        state = index;
      } else {
        state = false;
      }

      this.setState({accordion: state});
      event.preventDefault();
   }

   captureEvent(category, name){
     gtag('event', 'click', {
        'event_category': category,
        'event_label': name
      })
   }

   componentDidMount(){
     gtag('event', 'view', {
        'event_category': "Page View",
        'event_label': "Dosing Quick References"
      })
   }

    componentWillMount(){
      this.props.diagnosisActions.getDiagnoseList()
      this.props.diagnosisActions.getDiagnosesByGroupName()
    }

    toggle() {
      this.setState({ collapse: !this.state.collapse });
    }

    populateReference(referenceType, code){
      var referenceList = this.props.references
      var referenceGroup = _.find(referenceList, { 'name': referenceType, 'list':[{'code':code}] });
      var referenceObject = _.find(referenceGroup.list, { 'code': code });
      return referenceObject.typeName
    }

    render(){

      // alphabetizes groupNames after populating correct reference names
      // var populateReference = this.populateReference
      // var groups = []
      // _.each(this.props.diagnoseByGroupNameList, function(group){
      //   group.groupName = populateReference("Diagnosis Groups", group._id)
      //   groups.push(group)
      // })
      // groups = _.sortBy(groups, ["groupName"])

      // const diagnoses = groups.map((diagnosedGroup, index) =>
      //   <li key={index} onClick={() => {this.captureEvent("Diagnosis Group", this.populateReference("Diagnosis Groups", diagnosedGroup._id))}}>{this.props.references && diagnosedGroup.groupName}
      //     <ul>
      //       {diagnosedGroup.diagnoses && diagnosedGroup.diagnoses.map(diagnosis => {
      //         return(
      //           <li key={diagnosis._id}>
      //             <Link onClick={() => {this.captureEvent("Diagnosis", diagnosis.name)}} to={'/diagnosis/'+diagnosis._id}>
      //               {diagnosis.name}
      //             </Link>
      //           </li>
      //         )
      //       })}
      //     </ul>
      //   </li>
      // )

      var diagnosisGroups = _.sortBy(this.props.diagnoseByGroupNameList, ["_id"]);

      return(
        <div id="diagnoses-list">
          <SubPageHeaderComponent title={Enums.DIAGNOSES}/>

          {diagnosisGroups && diagnosisGroups.map((diagnoseGroup, index) => {
            return(
              <Card key={index}>
                <CollapseHeader onClick={(event) => this.onClick(event, index)}>
                  {this.props.references && this.populateReference("Diagnosis Groups", diagnoseGroup._id)}
                  <i className={'float-right ' + (this.state.accordion === index ? 'fa fa-angle-up' : 'fa fa-angle-down')}></i>
                </CollapseHeader>
                <Collapse isOpen={this.state.accordion === index}>
                  <ListGroup>
                      {diagnoseGroup.diagnoses.map((diagnosis, index) => {
                        return(
                          <ListGroupItem key={index}><Link to={"/diagnosis/"+diagnosis._id}>{diagnosis.name}</Link></ListGroupItem>
                        )
                      })
                      }
                  </ListGroup>
                </Collapse>
              </Card>
            )
          })}

          {/* {this.props.diagnoseByGroupNameList && this.props.diagnoseByGroupNameList.map((diagnoseGroup, index) => {
            return(
              <div key={index} className="list-group">
                <span className="list-group-item active waves-light">{this.props.references && this.populateReference("Diagnosis Groups", diagnoseGroup._id)}</span>
                {diagnoseGroup.diagnoses.map((diagnosis, index) => {
                  return(
                    <Link to={"/diagnosis/"+diagnosis._id} key={index} className="list-group-item waves-effect">{diagnosis.name}</Link>
                  )
                })

                }
              </div>
            )
          })
          } */}
        </div>
      )
    }
}

function mapStateToProps(state){
  return{
    diagnoseList: state.diagnoseState.diagnoseList,
    diagnoseByGroupNameList: state.diagnoseState.diagnoseByGroupNameList,
    references: state.configRefState.configRef
  }
}

function mapDispatchToProps(dispatch){
  return {
    diagnosisActions: bindActionCreators(DiagnosisActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiagnosesView);
