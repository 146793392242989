import React from 'react';
import {bindActionCreators} from 'redux';
import BaseComponent from '../common/BaseComponent';
import { connect } from 'react-redux';
import DosingGuidelinesView from '../../views/CompareDosingGuidelinesView'
import DosingGuidelineComponent from '../DosingGuideline/DosingGuidelineComponent'
import { Container, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'mdbreact';
import * as DrugDosageActions from '../../actions/drugdosage-actions';

class CompareDosingGuidelinesModal extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      uncheckedDrugIds: []
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentDidMount(){
    var checkedDrugIds = _.difference(this.props.allDrugIds, this.props.uncheckedDrugIds);
    console.log('allDrugIds', this.props.allDrugIds)
    console.log('checkedDrugIds', checkedDrugIds)
    this.props.DrugDosageActions.getFilteredDosings(checkedDrugIds)
    this.setState({dosingsList:this.props.dosingsList})
  }

  componentDidUpdate(nextProps){
    if(nextProps.dosingsList != this.state.dosingsList){
      console.log("componentDidUpdate", this.props.uncheckedDrugIds)
      var checkedDrugIds = _.difference(nextProps.allDrugIds, nextProps.uncheckedDrugIds);
      this.props.DrugDosageActions.getFilteredDosings(checkedDrugIds)
      this.setState({dosingsList:this.props.dosingsList})
    }
  }

    render() {
      return (
        <Container>
          <span className="select-deselect-all d-inline-block" onClick={this.toggle}>
            Dosing Guidelines
            <i className="fa fa-expand ml-1" aria-hidden="true"></i>
          </span>
          {/* <Button color="danger" onClick={this.toggle}>Modal</Button> */}
          <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
            <ModalHeader toggle={this.toggle}>Dosing Guidelines</ModalHeader>
            <ModalBody>
              <div>
                {/* <h1>Dosings: {this.props.dosingsList && this.props.dosingsList.length}</h1> */}
                {/* <SubPageHeaderComponent title="Dosing Guidelines"/> */}
                  <div className="page-content container">
                    {this.props.dosingsList && this.props.dosingsList.length > 0 && this.props.dosingsList.map((dosing, index) => {
                      return(
                        <div key={index} className="single-dosing">
                          <DosingGuidelineComponent  drugDosage={dosing} viewOnly={true}/>
                        </div>
                      )
                    })}
                  </div>
              </div>
            </ModalBody>
            {/* <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>Close</Button>{' '}
              <Button color="primary">Save changes</Button>
            </ModalFooter> */}
          </Modal>
        </Container>
      );
    }
 }

 function mapStateToProps(state){
   return{
     dosingsList: state.drugState.filteredDrugDosing
   }
 }

 function mapDispatchToProps(dispatch){
   return{
     DrugDosageActions: bindActionCreators(DrugDosageActions, dispatch)
   }
 }

 export default connect(mapStateToProps, mapDispatchToProps)(CompareDosingGuidelinesModal);
