import * as types from '../actions/action-types';
import initialState from './initialState';
import _ from 'lodash';

const drugReducer = function(state = initialState, action) {

  switch(action.type) {

    case types.GET_DRUGLIST_SUCCESS:
      return {...state, drugList:action.drugList};  //whatever our current state is, add on "drugList"

    case types.GET_DRUG_SUCCESS:
      return {...state, drug:action.drug};  //whatever our current state is, add on "drug"

    case types.GET_DRUGBYID_SUCCESS:
      return {...state, drug:action.drug};  //whatever our current state is, add on "drug"

    case types.GET_DRUGDOSAGE_SUCCESS:
      return {...state, drugDosage:action.drugDosage};  //whatever our current state is, add on "drug"

    case types.GET_DRUGDOSING_SUCCESS:
      return {...state, drugDosing:action.drugDosing};  //whatever our current state is, add on "drug"

    case types.GET_DRUGDOSINGS_SUCCESS:
      var dosings = [];
      dosings = _.orderBy(action.drugDosings, 'drug.name')
      return {...state, drugDosings:dosings};

    case types.GETLASTPUBLISHED_DRUGDOSING_SUCCESS:
      return {...state, lastPublishedDrugDosing:action.lastPublishedDrugDosing};

    case types.GET_FILTEREDDRUGDOSING_SUCCESS:
      var filteredDosings = []
      _.forEach(action.drugDosings, function(dosing) {
        if(_.includes(action.checkedDrugIds, dosing.drug._id)){
          filteredDosings.push(dosing)
        }
      });
      return {...state, filteredDrugDosing:filteredDosings};

    case types.GETDRUGSWITHDOSING_DRUGDOSING_SUCCESS:
      return {...state, drugsWithDosingList:action.drugsList};
  }
  return state;
}

export default drugReducer
