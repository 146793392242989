import React from  'react';
import PropTypes from  'prop-types';
import {connect} from  'react-redux';
import axios from 'axios';
import HomeView from './Home';
import SubpagesView from './Subpages';
import CompareDosingGuidelinesView from './CompareDosingGuidelinesView';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import * as configRefApi from '../api/configRef-api';
// import Header from  './Header';
// import Footer from  './Footer';
// import NavBar from  './NavBar';

 class App extends React.Component {

   constructor(props){
     super(props)
     this.state = {
       isAuthenticated: false
     }
   }

  render() {
    return (
      <div>
        {this.state.isAuthenticated &&
          <div>
            <Switch>
              <Route path="/" exact component={HomeView} />
              <Route exact path="/compareDosingGuidelines" component={CompareDosingGuidelinesView} />
              <Route path="/" component={SubpagesView} />
            </Switch>
          </div>
        }
      </div>
    )
  }

  componentWillMount(){
    
  }

  componentDidMount () {
    axios.post(process.env.ANTIBIOGRAM_SERVICES_BASE_URL+'login', {"username":"CLIENT_USER", "userId":1})
    .then(response => {
      console.log('User Authenticated')
      var token = String(response.data.token)
      sessionStorage.setItem('accessToken', token);
      axios.defaults.headers.common['Authorization'] = token
      this.setState({isAuthenticated:true})
      configRefApi.getConfigRefList()
      return response.data
    }).catch(function (error){
      console.log('Authentication failed')
      return error
    });
  }

  componentDidUpdate () {
  }

}


export default App
