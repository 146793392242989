import * as ActionTypes from './action-types';
import * as clientApi from '../api/client-api';


export function getBugsDrugsDiagnoses() {
  return function(dispatch) {
      return clientApi.getBugsDrugsDiagnoses()
      .then((searchList) => {
        dispatch({type: ActionTypes.GET_BUGSDRUGSDIAGNOSES_SUCCESS, searchList});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function getAllAlerts() {
  return function(dispatch) {
      return clientApi.getAllAlerts()
      .then((alertsList) => {
        dispatch({type: ActionTypes.GET_ALLALERTS_SUCCESS, alertsList});
      })
      .catch(error => {
        throw(error);
      })
  }
}

export function updateNavbarLocation(location) {
  return function(dispatch){
    dispatch({type: ActionTypes.UPDATE_NAV_LOCATION, location})
  }
}
