import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import DiagnosesView from './DiagnosesView';
import DiagnosisView from './DiagnosisView';
import DocumentsView from './DocumentsView';
import ContactView from './ContactView';
import SusceptibilityMatrixView from './SusceptibilityMatrixView';
import DosingGuidelinesView from './DosingGuidelinesView';
import DosingGuidelineView from './DosingGuidelineView';
import SideNavComponent from '../components/common/SideNavComponent';

class SubpagesView extends BaseComponent {

    constructor(props) {
      super(props)
    }

    componentWillMount(){
    }

    componentDidMount(){
    }

    render(){
      return(
        <div className="main">
          {(sessionStorage.getItem('preview') == "true") &&
            <span id="preview-watermark">PREVIEW</span>
          }
          <div className="container px-0 ">
            <Switch>
              <Route path="/diagnosis/:diagnosisId" component={DiagnosisView} />
              <Route path="/dosingGuideline/:drugId" component={DosingGuidelineView} />
              <Route exact path="/diagnoses" component={DiagnosesView} />
              <Route exact path="/susceptibilityMatrix" component={SusceptibilityMatrixView} />
              <Route path="/susceptibilityMatrix/bug/:bacteriaId" component={SusceptibilityMatrixView} />
              <Route path="/susceptibilityMatrix/drug/:drugId" component={SusceptibilityMatrixView} />
              <Route exact path="/dosingGuidelines" component={DosingGuidelinesView} />
              <Route exact path="/documents" component={DocumentsView}/>
              <Route exact path="/contact" component={ContactView}/>
            </Switch>
            <SideNavComponent/>
          </div>
        </div>
      )
    }
}

export default SubpagesView;
